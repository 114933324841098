import { sample } from 'effector'
import axios from 'axios'
import { $token } from '../user'
import {
  loadDishOptionsFx,
  updateDishOptionFx,
  createDishOptionFx,
  $rawDishOptions,
  $dishOptions,
} from './'

loadDishOptionsFx.use(async shopId => {
  const res = await axios.get(`/api/owner/shops/${shopId}/options`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${$token.getState()}`,
    },
  })

  return res.data.data
})

createDishOptionFx.use(async ({ option, shopId }) => {
  const res = await axios.post(
    `/api/owner/shops/${shopId}/options`,
    { ...option },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${$token.getState()}`,
      },
    },
  )

  return res.data.data
})

updateDishOptionFx.use(async ({ option, shopId, optionId }) => {
  const res = await axios.put(
    `/api/owner/shops/${shopId}/options/${optionId}`,
    { ...option },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${$token.getState()}`,
      },
    },
  )
  return res.data.data
})

$rawDishOptions
  .on(loadDishOptionsFx.done, (_, { result }) => result)
  .on(updateDishOptionFx.done, (dishOptions, { result }) =>
    dishOptions.map(option => (option.id === result.id ? result : option)),
  )
  .on(createDishOptionFx.done, (dishOptions, { result }) => [
    ...dishOptions,
    result,
  ])

sample({
  source: $rawDishOptions,
  fn: rawDishOptions =>
    rawDishOptions.map(option => ({ ...option, key: `option-${option.id}` })),
  target: $dishOptions,
})
