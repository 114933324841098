import React from 'react'
import moment from 'moment'
import Table from 'antd/lib/table'
import Tooltip from 'antd/lib/tooltip'
import Text from 'antd/lib/typography/Text'

const AllTransactionsTable = ({ dataSource, ...props }) => {
  const columns = [
    {
      title: 'Аккаунт',
      render: (_, { account: { title, full_name, email } }) =>
        title || full_name || email,
    },
    {
      title: 'Дата',
      render: (_, { createdAt }) => {
        const date = moment(createdAt).format('DD.MM.YYYY')
        const time = moment(createdAt).format('HH:mm')

        return (
          <Text>
            {date} <Text type="secondary">{time}</Text>
          </Text>
        )
      },
    },
    {
      title: 'Сумма',
      render: (_, { amount }) => {
        return (
          <Text strong type={Number(amount) >= 0 ? 'success' : 'danger'}>
            {amount}
          </Text>
        )
      },
    },
    {
      title: 'Источник',
      render: (_, { admin, report, billing, description }) => {
        if (admin) {
          return (
            <Text>
              <Text>
                {admin.login || 'Корректировка'}
                <Text type="secondary">
                  {description ? `: ${description}` : ''}
                </Text>
              </Text>
            </Text>
          )
        }

        if (report) {
          return (
            <Tooltip title={report.title}>
              <Text type="secondary">Автоматический отчёт</Text>
            </Tooltip>
          )
        }

        if (billing) {
          return <Text type="secondary">Пополнение счёта</Text>
        }

        return 'Неизвестный источник'
      },
    },
  ]

  return (
    <Table
      {...props}
      dataSource={dataSource}
      rowKey="id"
      columns={columns}
      scroll={{ x: true }}
      bordered
    />
  )
}

export default AllTransactionsTable
