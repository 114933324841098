import React, { useEffect } from 'react'
import { Switch, Route, useLocation, useHistory } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/ru'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import PrivateRoute from './components/PrivateRoute'
import 'antd/dist/antd.css'
import './App.css'
import ConfigProvider from 'antd/lib/config-provider'
import locale from 'antd/es/locale/ru_RU'

moment.updateLocale('ru', {
  week: {
    dow: 1,
  },
})

function App() {
  const { pathname } = useLocation()
  const { push } = useHistory()

  useEffect(() => {
    if (pathname.length < 2) {
      push('/stats')
    }
  }, [pathname, push])

  return (
    <ConfigProvider locale={locale}>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <PrivateRoute path="/:screen">
          <Dashboard />
        </PrivateRoute>
      </Switch>
    </ConfigProvider>
  )
}

export default App
