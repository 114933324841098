import { combine, createEffect, createStore } from 'effector'

export const loadStatsFx = createEffect()
export const $allStats = createStore([])
export const $orderStats = createStore([])
export const $earningStats = createStore([])
export const $avrgCheckStats = createStore([])
export const $canceledStats = createStore([])

export const $stats = combine({
  orders: $orderStats,
  earning: $earningStats,
  avrgCheck: $avrgCheckStats,
  canceled: $canceledStats,
})
