import { notification } from 'antd'
import moment from 'moment'

export function copyToClipBoard(record) {
  const codeStr = `Промокод: ${record.code.toUpperCase()}`
  const discountStr = `
Скидка: ${record.discount} ₽`
  const timeStr = record.to
    ? `
Срок действия до ${moment(record.to).format('DD.MM.YYYY')}`
    : ''
  const minSumStr = record.minOrderSum
    ? `
Минимальная сумма заказа от ${record.minOrderSum} ₽`
    : ''
  const flagsStr =
    record.first || record.mobile
      ? `
Действует только${record.first ? ' на первый заказ' : ''}${
          record.mobile ? ' в мобильном приложении' : ''
        }`
      : ''
  const userStr =
    record.restaurant || record.customer_phone
      ? `
Применим только${record.restaurant && ` в "${record.restaurant.title}"`}${
          record.customer_phone &&
          ` для пользователя с телефоном +${record.customer_phone}`
        }`
      : ''

  const messageText = `${codeStr}${discountStr}${timeStr}${minSumStr}${flagsStr}${userStr}`

  const description =
    messageText.length > 63 ? `${messageText.slice(0, 64)}…` : messageText

  navigator.clipboard.writeText(messageText).then(
    function () {
      notification['info']({
        message: 'Информация скопирована',
        description,
      })
    },
    function () {
      notification['error']({
        message: 'Не удалось скопировать текст',
      })
    },
  )
}

export const PROMOCODE_CONFIG = {
  numbers: true,
  uppercase: false,
  lowercase: true,
  symbols: false,
  length: 6,
}

export const fastPromoConfig = {
  limit: 1,
  mobile: true,
  first: true,
  active: true,
}

export const fastPromos = [
  {
    discount: 50,
    minSum: 500,
    duration: 30,
  },
  {
    discount: 100,
    minSum: 500,
    duration: 30,
  },
  {
    discount: 150,
    minSum: 500,
    duration: 30,
  },
  {
    discount: 200,
    minSum: 500,
    duration: 30,
  },
  {
    discount: 250,
    minSum: 500,
    duration: 30,
  },
  {
    discount: 500,
    minSum: 500,
    duration: 30,
  },
  {
    discount: 1000,
    minSum: 500,
    duration: 30,
  },
]
