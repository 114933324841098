import React, { useEffect, useState } from 'react'
import ScreenLayout from '../../components/ScreenLayout'
import PageHeader from '../../components/PageHeader'
import { MinusCircleOutlined } from '@ant-design/icons'
import { useStore } from 'effector-react'
import Image from 'antd/lib/image'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import InputNumber from 'antd/lib/input-number'
import Modal from 'antd/lib/modal'
import Space from 'antd/lib/space'
import Table from 'antd/lib/table'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Radio from 'antd/lib/radio'
import Text from 'antd/lib/typography/Text'
import formLayout, { tailLayout } from '../../params/formLayout'
import {
  $enreachedStories,
  createStoryFx,
  loadStoriesFx,
  updateStoryFx,
} from '../../models/stories'

const StoriesScreen = () => {
  const [addModal, setAddModal] = useState(false)
  const [editKey, setEditKey] = useState(0)
  const stories = useStore($enreachedStories)

  const [form] = Form.useForm()

  useEffect(() => {
    if (!stories) {
      loadStoriesFx()
    }
  }, [stories])

  const columns = [
    { title: 'ID', width: 'min-content', dataIndex: 'id' },
    {
      title: 'Обложка',
      width: 'min-content',
      render: (_, { preview_url, title }) => (
        <Image width={64} src={preview_url} alt={title} preview={false} />
      ),
    },
    {
      title: 'Название',
      dataIndex: 'title',
    },
    { title: 'Позиция', width: 'min-content', dataIndex: 'order' },
    {
      title: 'Статус',
      width: 'min-content',
      render: (_, { status }) =>
        status === 'on' ? (
          <Text type="success">Вкл</Text>
        ) : (
          <Text type="danger">Выкл</Text>
        ),
    },
    {
      width: 'min-content',
      render: (_, record) => (
        <Button
          onClick={() => {
            setEditKey(record.id)
            form.setFieldsValue(record)
            setAddModal(true)
          }}
        >
          Редактировать
        </Button>
      ),
    },
  ]

  return (
    <ScreenLayout>
      <PageHeader title="Истории" />
      <Space style={{ marginBottom: 16 }}>
        <Button
          type="primary"
          onClick={() => {
            setEditKey(0)
            setAddModal(true)
          }}
        >
          Добавить историю
        </Button>
      </Space>
      <Table
        dataSource={stories}
        columns={columns}
        pagination={false}
        bordered
      />

      <Modal
        title="Добавить историю"
        visible={addModal}
        onCancel={() => setAddModal(false)}
        footer={null}
        width={640}
      >
        <Form
          form={form}
          component={false}
          onFinish={values => {
            setAddModal(false)
            form.resetFields()

            if (editKey > 0) {
              updateStoryFx({ params: values, id: editKey })
            } else {
              createStoryFx(values)
            }
          }}
          {...formLayout}
        >
          <Form.Item label="Заголовок" name="title" required>
            <Input placeholder="Введите заголовок" />
          </Form.Item>
          <Form.Item label="Позиция" name="order" required>
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              placeholder="Введите номер позиции"
            />
          </Form.Item>
          <Form.Item label="Обложка" name="preview_url" required>
            <Input placeholder="https://image.com/image.jpg" />
          </Form.Item>
          <Form.Item label="Изображения" required>
            <Form.List name="img_urls">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(field => (
                    <Form.Item key={field.key}>
                      <Row
                        gutter={16}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Col span={22}>
                          <Form.Item noStyle name={field.name} required>
                            <Input placeholder="https://image.com/image.jpg" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Text type="danger">
                            <MinusCircleOutlined
                              onClick={() => remove(field.name)}
                            />
                          </Text>
                        </Col>
                      </Row>
                    </Form.Item>
                  ))}
                  <Button type="dashed" onClick={() => add()} block>
                    Добавить изображение
                  </Button>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item label="Состояние" name="status" required>
            <Radio.Group>
              <Radio.Button value="on">Вкл</Radio.Button>
              <Radio.Button value="off">Выкл</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space>
              <Button type="primary" onClick={() => form.submit()}>
                Сохранить
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </ScreenLayout>
  )
}

export default StoriesScreen
