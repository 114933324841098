import React from 'react'
import { Table, Button, Typography, Modal } from 'antd'
import moment from 'moment'
import { StopOutlined } from '@ant-design/icons'
import { unbanCustomerFx } from '../../../models/blacklist'

const { Text } = Typography

const BlackListTable = ({ data }) => {
  const columns = [
    { title: 'Номер телефона', render: (_, { phone }) => phone },
    {
      title: 'Дата блокировки',
      render: (_, { createdAt }) => {
        const _date = moment(createdAt)
        const time = _date.format('HH:MM')
        const date = _date.format('DD.MM.YYYY')

        return (
          <Text>
            {date} <Text type="secondary">{time}</Text>
          </Text>
        )
      },
    },
    {
      width: 'min-content',
      render: (_, { phone }) => (
        <Button
          type="submit"
          onClick={() =>
            Modal.confirm({
              title: 'Отмена блокировки',
              icon: <StopOutlined />,
              content: `Разблокирвать номер ${phone}`,
              okText: 'Подтвердить',
              onOk: () => unbanCustomerFx(phone),
            })
          }
        >
          Разблокировать
        </Button>
      ),
    },
  ]
  return <Table columns={columns} rowKey="id" dataSource={data} bordered />
}

export default BlackListTable
