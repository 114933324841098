import { createStore, createEffect, createEvent } from 'effector'

export const loadRestaurantsFx = createEffect()
export const createRestaurantFx = createEffect()
export const updateRestaurantFx = createEffect()
export const launchRestaurantFx = createEffect()

export const $rawRestaurantsData = createStore([])
export const $restaurants = createStore([])

export const $restaurantFormModal = createStore(false)
export const restaurantFormModalSet = createEvent('restaurant form modal set')
