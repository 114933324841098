import { sample, combine } from 'effector'
import axios from 'axios'
import { $token, userLoggedOut } from '../user'
import { $paymentTypes } from '../paymentTypes'
import { $owners } from '../owners'
import {
  loadRestaurantsFx,
  createRestaurantFx,
  updateRestaurantFx,
  $rawRestaurantsData,
  $restaurants,
  $restaurantFormModal,
  restaurantFormModalSet,
  launchRestaurantFx,
} from './'
import { addAddressesFx, deleteAddressFx } from '../restAddresses'
import message from 'antd/lib/message'

loadRestaurantsFx.use(async () => {
  const res = await axios.get('/api/admin/shops', {
    headers: { Authorization: `Bearer ${$token.getState()}` },
  })
  return res.data.data
})

loadRestaurantsFx.fail.watch(({ error }) => {
  if (error.response.status) userLoggedOut()
})

createRestaurantFx.use(async shop => {
  const res = await axios.post('/api/admin/shops', shop, {
    headers: {
      Authorization: `Bearer ${$token.getState()}`,
      'Content-Type': 'application/json',
    },
  })
  return res.data.createdShop
})

updateRestaurantFx.use(async shop => {
  const res = await axios.put('/api/admin/update_shop', shop, {
    headers: {
      Authorization: `Bearer ${$token.getState()}`,
      'Content-Type': 'application/json',
    },
  })
  return res.data.updatedShop
})

updateRestaurantFx.done.watch(({ result }) =>
  message.success(`Информация для ${result.title} успешно обновлена`),
)

updateRestaurantFx.fail.watch(() => message.success('Произошла ошибка'))

launchRestaurantFx.use(async restaurantId => {
  const { data } = await axios.put(
    `/api/admin/shops/${restaurantId}/launch`,
    {},
    {
      headers: {
        Authorization: `Bearer ${$token.getState()}`,
        'Content-Type': 'application/json',
      },
    },
  )

  return data
})

launchRestaurantFx.done.watch(({ result }) => {
  if (result.code === 'ok') {
    message.success('Операция произведена успешно')
  } else {
    message.error('Произошла ошибка')
  }
})

$rawRestaurantsData
  .on(loadRestaurantsFx.done, (_, { result }) => result)
  .on(createRestaurantFx.done, (restaurants, { result }) => [
    ...restaurants,
    result,
  ])
  .on(updateRestaurantFx.done, (restaurants, { result }) =>
    restaurants.map(restaurant =>
      restaurant.id === result.id ? result : restaurant,
    ),
  )
  .on(deleteAddressFx.done, (restaurants, { result }) => {
    if (result.address) {
      return restaurants.map(restaurant => {
        if (restaurant.id === result.shop_id) {
          const shopAddresses = restaurant.shopAddresses.filter(
            ({ id }) => id !== result.id,
          )
          return { ...restaurant, shopAddresses }
        }
        return restaurant
      })
    }
    return restaurants
  })
  .on(addAddressesFx.done, (restaurants, { result }) => {
    if (result.code === 'ok') {
      return restaurants.map(restaurant => {
        const newAddress = result.data
        if (restaurant.id === newAddress.shop_id) {
          return {
            ...restaurant,
            shopAddresses: [...restaurant.shopAddresses, newAddress],
          }
        }
        return restaurant
      })
    }
    return restaurants
  })

sample({
  source: combine({
    restaurants: $rawRestaurantsData,
    allPaymentTypes: $paymentTypes,
    owners: $owners,
  }),
  fn: ({ restaurants, allPaymentTypes, owners }) => {
    if (
      restaurants.length > 0 &&
      allPaymentTypes.length > 0 &&
      owners.length > 0
    ) {
      return restaurants.map(restaurant => {
        const {
          id,
          payment_type: sourcePaymentType,
          account_id,
          contact: _contact,
          deliveryPrices: sourceDeliveryPrices,
          dishcategories: _dishcategories,
        } = restaurant
        const key = `restaurant-${id}`
        const paymentTypes = sourcePaymentType
          ? allPaymentTypes.filter(({ id }) => sourcePaymentType.includes(id))
          : []
        const payment_type = sourcePaymentType || []
        const deliveryPrices = sourceDeliveryPrices || {}
        const ownerEmail = owners.find(({ id }) => id === account_id).email
        let contact = ''

        if (_contact) {
          contact =
            _contact.indexOf('8') === 0
              ? _contact.replace('8', '7')
              : _contact.indexOf('9') === 0
              ? `7${_contact}`
              : _contact.indexOf('+') === 0
              ? _contact.replace('+', '')
              : _contact
          contact = '+' + contact
        } else {
          contact = 'Не указан'
        }

        const dishcategories = _dishcategories.map(({ id }) => id)

        return {
          ...restaurant,
          key,
          paymentTypes,
          payment_type,
          deliveryPrices,
          ownerEmail,
          contact,
          dishcategories,
        }
      })
    }
  },
  target: $restaurants,
})

$restaurantFormModal
  .on(restaurantFormModalSet, (_, value) => !!value)
  .on(createRestaurantFx.done, () => false)
  .on(updateRestaurantFx.done, () => false)
