import React, { useState, useRef, useEffect } from 'react'
import { useStore } from 'effector-react'
import {
  Table,
  Button,
  Modal,
  Typography,
  List,
  Space,
  Popover,
  Dropdown,
  Menu,
} from 'antd'

import ScreenLayout from '../../components/ScreenLayout'
import PageHeader from '../../components/PageHeader'
import {
  $accountModal,
  $detailsModal,
  $owners,
  accountModalSet,
  detailsModalSet,
  loadOwnersFx,
  toggleOwnerStatusFx,
} from '../../models/owners'
import getColumnSearchProps from '../../params/columnSearchProps'
import {
  EditOutlined,
  EllipsisOutlined,
  LockOutlined,
  UnlockOutlined,
  UserOutlined,
} from '@ant-design/icons'
import AccountForm from './OwnersScreen/AccountForm'
import DetailsForm from './OwnersScreen/DetailsForm'
import { formatPhoneNumber } from '../../utils/helpers'
import { Link } from 'react-router-dom'

const { Text } = Typography

function OwnersScreen() {
  const owners = useStore($owners)
  const ownersLoading = useStore(loadOwnersFx.pending)
  const accountModal = useStore($accountModal)
  const detailsModal = useStore($detailsModal)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const titleSearchRef = useRef()
  const phoneSearchRef = useRef()
  const emailSearchRef = useRef()
  const fullnameSearchRef = useRef()

  useEffect(() => {
    loadOwnersFx()
  }, [])

  const columns = [
    {
      title: 'Название',
      render: (_, { title, shops }) => {
        return (
          <Popover
            title="Список ресторанов"
            content={
              <List
                rowKey="id"
                size="small"
                itemLayout="vertical"
                dataSource={shops}
                renderItem={({ title }) => {
                  return <List.Item>{title}</List.Item>
                }}
              />
            }
          >
            {title}
          </Popover>
        )
      },
      ...getColumnSearchProps('title', 'Поиск по названию', titleSearchRef),
    },
    {
      title: 'ФИО',
      dataIndex: 'full_name',
      ...getColumnSearchProps('full_name', 'Поиск по имени', fullnameSearchRef),
    },
    {
      title: 'Телефон',
      render: (_, { contact }) => {
        return contact ? (
          <a href={`tel:${contact}`}>{formatPhoneNumber(contact)}</a>
        ) : null
      },
      ...getColumnSearchProps('contact', 'Поиск по номеру', phoneSearchRef),
    },
    {
      title: 'Логин (email)',
      dataIndex: 'email',
      name: 'email',
      ...getColumnSearchProps('email', 'Поиск по логину', emailSearchRef),
    },
    {
      title: 'Баланс',
      sorter: (a, b) => Number(a.balance) - Number(b.balance),
      render: (_, { id, balance }) => {
        return (
          <Space>
            <Link to={`/reports/transactions/?ownerId=${id}`}>
              <Text strong type={Number(balance) < 0 ? 'danger' : 'success'}>
                {balance}
              </Text>
            </Link>
          </Space>
        )
      },
    },
    {
      title: 'Статус',
      width: 'min-content',
      sorter: (a, b) => a.status > b.status,
      render: (_, { status }) => {
        return (
          <Text type={status ? 'danger' : 'success'}>
            {status ? 'Заблокирован' : 'Активен'}
          </Text>
        )
      },
    },
    {
      name: 'actions',
      width: 'min-content',
      render: (_, record) => {
        const { status: isBlocked, email, title, full_name, balance } = record
        const accName = title || full_name || email
        const actionName = isBlocked ? 'Разблокировать' : 'Заблокировать'
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  icon={<EditOutlined />}
                  onClick={() => {
                    detailsModalSet(true)
                    setSelectedAccount(record)
                  }}
                >
                  Информация
                </Menu.Item>
                <Menu.Item
                  icon={<UserOutlined />}
                  onClick={() => {
                    accountModalSet(true)
                    setSelectedAccount(record)
                  }}
                >
                  Логин и пароль
                </Menu.Item>
                <Menu.Item
                  danger={!isBlocked}
                  icon={isBlocked ? <UnlockOutlined /> : <LockOutlined />}
                  onClick={() => {
                    Modal.confirm({
                      title: `${actionName} ${accName}?`,
                      width: 480,
                      content: (
                        <Text>
                          Баланс этого аккаунта{' '}
                          <Text strong>{balance} руб.</Text>
                        </Text>
                      ),
                      okButtonProps: { danger: !isBlocked },
                      okText: actionName,
                      onOk: () => toggleOwnerStatusFx(record.id),
                    })
                  }}
                >
                  {actionName}
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
            trigger={['click']}
          >
            <Button icon={<EllipsisOutlined />} />
          </Dropdown>
        )
      },
    },
  ]

  return (
    <ScreenLayout>
      <PageHeader title="Аккаунты владельцев" />
      <Button
        type="primary"
        style={{ marginBottom: 16 }}
        onClick={() => {
          accountModalSet(true)
          setSelectedAccount(null)
        }}
      >
        Добавить владельца
      </Button>
      <Table
        bordered
        loading={ownersLoading}
        dataSource={owners}
        columns={columns}
        rowKey="id"
        scroll={{ x: true }}
        pagination={{ showSizeChanger: true }}
      />

      <Modal
        title={`${selectedAccount ? 'Редактирование' : 'Создание'} аккаунта`}
        centered
        visible={accountModal}
        width={640}
        onCancel={() => accountModalSet(false)}
        footer={false}
      >
        <AccountForm account={selectedAccount} />
      </Modal>

      <Modal
        title="Редактирование информации аккаунта"
        centered
        visible={detailsModal}
        width={640}
        onCancel={() => detailsModalSet(false)}
        footer={false}
      >
        <DetailsForm account={selectedAccount} />
      </Modal>
    </ScreenLayout>
  )
}

export default OwnersScreen
