import React, { useEffect, useState } from 'react'
import { useStore } from 'effector-react'
import Typography from 'antd/lib/typography'
import Divider from 'antd/lib/divider'

import ScreenLayout from '../../components/ScreenLayout'
import PageHeader from '../../components/PageHeader'
import OrdersTable from '../../components/OrdersTable'

import { loadOrdersFx, $orders, $ordersMeta } from '../../models/orders'
import Search from './OrdersScreen/Search'

const { Text } = Typography

/**
 * Информационная панель заказов ресторана
 */
function OrderScreens() {
  const orders = useStore($orders)
  const meta = useStore($ordersMeta)
  const loading = useStore(loadOrdersFx.pending)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (meta?.totalPages < page) {
      setPage(1)
    }
  }, [meta, page])

  useEffect(() => {
    loadOrdersFx({ page, perPage, search })
  }, [page, perPage, search])

  return (
    <ScreenLayout>
      <PageHeader title={<Text>Заказы</Text>} />
      <Search onSearch={setSearch} />
      <Divider />
      <OrdersTable
        dataSource={orders}
        loading={loading}
        bordered
        pagination={{
          current: page,
          showSizeChanger: true,
          total: meta?.totalResults,
          pageSizeOptions: [10, 20, 30],
          onChange: (page, pageSize) => {
            setPage(page)
            setPerPage(pageSize)
          },
          pageSize: perPage,
        }}
      />
    </ScreenLayout>
  )
}

export default OrderScreens
