import axios from 'axios'
import { $token, userLoggedOut } from '../user'

import {
  loadOwnersFx,
  createOwnerFx,
  updateOwnerFx,
  $owners,
  $accountModal,
  accountModalSet,
  $detailsModal,
  detailsModalSet,
  updateOwnerDetailsFx,
  toggleOwnerStatusFx,
} from './'
import { message } from 'antd'

loadOwnersFx.use(async () => {
  const res = await axios.get('/api/admin/owners', {
    headers: {
      Authorization: `Bearer ${$token.getState()}`,
    },
  })
  return res.data.data
})

loadOwnersFx.fail.watch(({ error }) => {
  if (error.response.status) userLoggedOut()
})

createOwnerFx.use(async values => {
  const res = await axios.post(`/api/owner/register`, values, {
    headers: {
      Authorization: `Bearer ${$token.getState()}`,
      'Content-Type': 'application/json',
    },
  })
  return res.data.newOwner
})

createOwnerFx.done.watch(() =>
  message.success('Аккаунт партнёра успешно создан'),
)
createOwnerFx.fail.watch(() =>
  message.error('Не удалось создать аккаунт партнёра'),
)

updateOwnerFx.use(async ({ owner_id, values }) => {
  const res = await axios.put(`/api/admin/owners/${owner_id}`, values, {
    headers: {
      Authorization: `Bearer ${$token.getState()}`,
      'Content-Type': 'application/json',
    },
  })
  return res.data.data
})

updateOwnerFx.done.watch(() => message.success('Изменения сохранены'))
updateOwnerFx.fail.watch(() => message.error('Не удалось сохранить изменения'))

updateOwnerDetailsFx.use(async ({ id, details }) => {
  const { data } = await axios.put(`/api/admin/owners/${id}/info`, details, {
    headers: { Authorization: `Bearer ${$token.getState()}` },
  })

  return data.data
})

updateOwnerDetailsFx.done.watch(() => message.success('Данные сохранены'))
updateOwnerDetailsFx.fail.watch(() =>
  message.error('Не удалось сохранить данные'),
)

toggleOwnerStatusFx.use(async accountId => {
  const { data } = await axios.put(
    `/api/admin/owners/${accountId}/toggle`,
    {},
    {
      headers: { Authorization: `Bearer ${$token.getState()}` },
    },
  )

  if (data.code === 'ok') {
    return data.data
  } else {
    message.error(data.message)
    throw new Error(data.message)
  }
})

$owners
  .on(loadOwnersFx.done, (_, { result }) => result)
  .on(createOwnerFx.done, (owners, { result }) => [...owners, result])
  .on(updateOwnerFx.done, (owners, { result }) =>
    owners.map(owner => (owner.id === result.id ? result : owner)),
  )
  .on(updateOwnerDetailsFx.done, (owners, { result }) =>
    owners.map(owner =>
      owner.id === result.id ? { ...owner, ...result } : owner,
    ),
  )
  .on(toggleOwnerStatusFx.done, (owners, { result }) =>
    owners.map(owner =>
      owner.id === result.id ? { ...owner, ...result } : owner,
    ),
  )

$accountModal
  .on(accountModalSet, (_, value) => !!value)
  .on(createOwnerFx.done, () => false)
  .on(updateOwnerFx.done, () => false)

$detailsModal
  .on(detailsModalSet, (_, value) => !!value)
  .on(updateOwnerDetailsFx.done, () => false)
