import React, { useEffect, useState } from 'react'
import { useStore } from 'effector-react'
import ReactInputMask from 'react-input-mask'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Upload from 'antd/lib/upload'
import Button from 'antd/lib/button'
import Select from 'antd/lib/select'
import InputNumber from 'antd/lib/input-number'
import Tooltip from 'antd/lib/tooltip'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Checkbox from 'antd/lib/checkbox'
import message from 'antd/lib/message'
import { Divider, Space, Switch } from 'antd'

import { UploadOutlined } from '@ant-design/icons'

import formLayout, { tailLayout } from '../../../params/formLayout'
import {
  $restaurants,
  createRestaurantFx,
  updateRestaurantFx,
} from '../../../models/restaurants'
import { $token } from '../../../models/user'
import { $owners } from '../../../models/owners'
import { $restaurantCategories } from '../../../models/restaurantCategories'
import { $paymentTypes } from '../../../models/paymentTypes'
import {
  DEFAULT_RESTAURANT_SCHEDULE,
  PHONE_MASK,
} from '../../../utils/constants'

const RestaurantForm = ({ id = 0 }) => {
  const [form] = Form.useForm()
  const [initials, setInitials] = useState(undefined)
  const [image, setImage] = useState([{ uid: '1', thumbUrl: '' }])
  const restaurants = useStore($restaurants)
  const restaurantCategories = useStore($restaurantCategories)
  const paymentTypes = useStore($paymentTypes)
  const owners = useStore($owners)
  const token = useStore($token)

  useEffect(() => {
    const restaurant = restaurants.find((restaurant) => restaurant.id === id)
    if (restaurant) {
      const deliveryPrices = JSON.stringify(restaurant.deliveryPrices)
      setInitials({ ...restaurant, deliveryPrices })
      setImage([{ uid: 1, thumbUrl: restaurant.image }])
    } else {
      setInitials(undefined)
    }
  }, [id, form, restaurants])

  useEffect(() => {
    form.resetFields()
  }, [initials, form])

  const uploadConfig = {
    action: '/api/admin/upload_image',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    listType: 'picture',
    showUploadList: { showRemoveIcon: false },
    onChange(info) {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      fileList = fileList.map((file) => {
        if (file.response) {
          file.thumbUrl = file.response.data.url
        }
        return file
      })
      setImage(fileList)
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  function onEditSave(values) {
    try {
      const result = {
        ...values,
        deliveryPrices: JSON.parse(values.deliveryPrices),
        takeaway: !!values.takeaway,
        notifyOrders: !!values.notifyOrders,
        notifyCalls: !!values.notifyCalls,
        notifyFeedbacks: !!values.notifyFeedbacks,
        enabled: !!values.enabled,
        available: !!values.available,
        confirmed: !!values.confirmed,
        contact: values.contact.replace(/\D+/g, ''),
        image: image[0].thumbUrl,
        type: 1,
      }

      if (id > 0) {
        updateRestaurantFx({ ...result, id })
      } else {
        createRestaurantFx({
          ...result,
          work_time: DEFAULT_RESTAURANT_SCHEDULE,
        })
      }
    } catch (errInfo) {
      message.error('Ошибка валидации')
    }
  }

  return (
    <Form
      form={form}
      initialValues={initials}
      component={false}
      onFinish={onEditSave}
      {...formLayout}
    >
      <Form.Item
        label="Название"
        name="title"
        rules={[{ required: true, message: 'Это обязательное поле!' }]}
      >
        <Input placeholder="Введите название ресторана" />
      </Form.Item>
      <Form.Item label="Изображение" name="image">
        <Upload {...uploadConfig} fileList={image}>
          <Button>
            <UploadOutlined /> Нажмите для загрузки
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item
        label="Владелец"
        name="account_id"
        rules={[{ required: true, message: 'Это обязательное поле!' }]}
      >
        <Select placeholder="Выберите аккаунт владельца">
          {owners.map(({ id, email, title, full_name }) => (
            <Select.Option value={id} key={`owner-${id}`}>
              {title || full_name || email}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Категории"
        name="dishcategories"
        rules={[{ required: true, message: 'Это обязательное поле!' }]}
      >
        <Select placeholder="Выберите категории" mode="multiple">
          {restaurantCategories.map(({ id, title, key }) => (
            <Select.Option value={id} key={key}>
              {title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Типы оплаты"
        name="payment_type"
        rules={[{ required: true, message: 'Это обязательное поле!' }]}
      >
        <Select placeholder="Выберите типы оплаты" mode="multiple">
          {paymentTypes.map(({ id, caption, key }) => (
            <Select.Option value={id} key={key}>
              {caption}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={'Время доставки'}
        name="avg_delivery_time"
        rules={[{ required: true, message: 'Это обязательное поле!' }]}
      >
        <InputNumber
          min={0}
          placeholder="Укажите среднее время в минутах"
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item
        label="Условия доставки"
        name="deliveryPrices"
        rules={[
          {
            required: true,
            message: 'Обязательное поле!',
          },
        ]}
      >
        <Input.TextArea
          autoSize={true}
          placeholder="Введите таблицу условий в формате JSON"
          style={{ whiteSpace: 'pre' }}
        />
      </Form.Item>
      <Form.Item
        label="Контакт"
        name="contact"
        rules={[
          {
            required: true,
            message: 'Обязательное поле!',
          },
        ]}
      >
        <ReactInputMask mask={PHONE_MASK}>
          <Input type="phone" placeholder="Введите номер телефона" allowClear />
        </ReactInputMask>
      </Form.Item>
      <Form.Item
        label="Telegram ID"
        name="telegram_id"
        rules={[{ required: true, message: 'Обязательное поле!' }]}
      >
        <Input type="text" placeholder="Введите Telegram ID ресторана" />
      </Form.Item>
      <Form.Item label="Комиссия / кешбек">
        <Input.Group>
          <Row gutter={9}>
            <Col span={12}>
              <Tooltip title="Размер комиссии">
                <Form.Item
                  name="commission"
                  rules={[
                    {
                      required: true,
                      message: 'Обязательное поле!',
                    },
                  ]}
                  style={{ margin: 0 }}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    placeholder="Укажите размер комиссии в %"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col span={12}>
              <Tooltip title="Размер кешбека">
                <Form.Item name="bonusPercent" style={{ margin: 0 }}>
                  <InputNumber
                    min={0}
                    max={100}
                    placeholder="Размер кешбека в %"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>
        </Input.Group>
      </Form.Item>
      <Form.Item label="Самовывоз">
        <Input.Group>
          <Row gutter={8}>
            <Col span={4}>
              <Form.Item
                style={{ margin: 0 }}
                name="takeaway"
                valuePropName="checked"
              >
                <Checkbox>Вкл.</Checkbox>
              </Form.Item>
            </Col>
            <Col span={20}>
              <Tooltip title="Размер скидки за самовывоз в %">
                <Form.Item style={{ margin: 0 }} name="takeawayDiscount">
                  <InputNumber
                    min={0}
                    placeholder="Размер скидки за самовывоз в %"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>
        </Input.Group>
      </Form.Item>
      <Form.Item label="Уведомления">
        <Input.Group>
          <Row>
            <Col span={8}>
              <Form.Item
                style={{ margin: 0 }}
                name="notifyOrders"
                valuePropName="checked"
              >
                <Checkbox>Заказы</Checkbox>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ margin: 0 }}
                name="notifyCalls"
                valuePropName="checked"
              >
                <Checkbox>Звонки</Checkbox>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ margin: 0 }}
                name="notifyFeedbacks"
                valuePropName="checked"
              >
                <Checkbox>Отзывы</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>
      </Form.Item>
      <Form.Item label="Модификаторы">
        <Input.Group>
          <Row>
            <Col span={8}>
              <Form.Item
                style={{ margin: 0 }}
                name="enabled"
                valuePropName="checked"
              >
                <Checkbox>Запущен</Checkbox>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ margin: 0 }}
                name="available"
                valuePropName="checked"
              >
                <Checkbox>Доступен</Checkbox>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ margin: 0 }}
                name="confirmed"
                valuePropName="checked"
              >
                <Checkbox>Отобразить</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>
      </Form.Item>
      <Divider />
      <Form.Item label="Служба доставки">
        <Input.Group>
          <Row>
            <Col span={8}>
              <Form.Item
                name="is_connected_to_delivery_service"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                label={'Время готовки'}
                name="approximate_order_preparation_time"
              >
                <InputNumber
                  min={0}
                  placeholder="Укажите среднее время в минутах"
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Space>
          <Button onClick={() => form.resetFields()}>Сброс</Button>
          <Button type="primary" onClick={() => form.submit()}>
            Сохранить
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default RestaurantForm
