const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

export const tailLayout = {
  wrapperCol: { offset: 6, span: 18 },
}

export default formLayout
