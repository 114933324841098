import React, { useEffect, useState, Fragment } from 'react'
import { useStore } from 'effector-react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import queryString from 'query-string'
import Text from 'antd/lib/typography/Text'
import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'
import Select from 'antd/lib/select'
import Space from 'antd/lib/space'
import Tabs from 'antd/lib/tabs'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Input from 'antd/lib/input'
import InputNumber from 'antd/lib/input-number'
import Descriptions from 'antd/lib/descriptions'
import PageHeader from '../../components/PageHeader'
import ScreenLayout from '../../components/ScreenLayout'
import TransactionsTable from '../../components/TransactionsTable'
import ReportsTable from './Reports/Table'
import { $owners, loadOwnersFx } from '../../models/owners'
import {
  $balance,
  $transactions,
  loadTransactionsFx,
  makeTransactionFx,
} from '../../models/transactions'
import formLayout, { tailLayout } from '../../params/formLayout'
import { $reports, loadReportsFx } from '../../models/reports'

const { TabPane } = Tabs

const Reports = () => {
  const { tab } = useParams()
  const [ownersFilter, setOwnersFilter] = useState('')
  const [selectedOwner, setSelectedOwner] = useState(null)
  const [transactionModal, setTransactionModal] = useState(false)
  const balance = useStore($balance)
  const transactions = useStore($transactions)
  const owners = useStore($owners)
  const reports = useStore($reports)
  const ownersLoading = useStore(loadOwnersFx.pending)
  const transactionPending = useStore(makeTransactionFx.pending)
  const history = useHistory()
  const { search } = useLocation()
  const { ownerId } = queryString.parse(search)
  const [form] = Form.useForm()

  useEffect(() => {
    const savedOwner = localStorage.getItem('selected_owner')

    if (savedOwner) {
      setSelectedOwner(JSON.parse(savedOwner))
    }
  }, [])

  useEffect(() => {
    if (ownerId && owners) {
      const owner = owners.find(
        ({ id }) => id.toString() === ownerId.toString(),
      )

      if (owner) {
        setSelectedOwner(owner)
      }
    }
  }, [ownerId, owners])

  useEffect(() => {
    if (selectedOwner) {
      localStorage.setItem('selected_owner', JSON.stringify(selectedOwner))
      loadTransactionsFx(selectedOwner.id)
      loadReportsFx(selectedOwner.id)
    }
  }, [selectedOwner])

  return (
    <ScreenLayout>
      <PageHeader title="Отчёты" />
      <Space direction="vertical">
        <Select
          style={{ width: 320 }}
          showSearch
          loading={ownersLoading}
          placeholder="Выберите аккаунт"
          value={selectedOwner && selectedOwner.id}
          onSearch={search => setOwnersFilter(search.toLowerCase())}
          onChange={value => {
            const owner = owners.find(({ id }) => id === value)
            setSelectedOwner(owner)
          }}
          filterOption={false}
          notFoundContent={null}
        >
          {owners
            .filter(({ title, full_name, email }) =>
              `${title} ${full_name} ${email}`
                .toLowerCase()
                .includes(ownersFilter.toLowerCase()),
            )
            .map(({ id, email, title, full_name }) => (
              <Select.Option key={`owner-${id}`} value={id}>
                {title || full_name || email}
              </Select.Option>
            ))}
        </Select>
        {owners.length > 0 && selectedOwner && (
          <Fragment>
            <Divider />
            <Descriptions>
              <Descriptions.Item label="Текущий баланс">
                {balance && (
                  <Text
                    strong
                    type={Number(balance) < 0 ? 'danger' : 'success'}
                  >
                    {balance} руб.
                  </Text>
                )}
              </Descriptions.Item>
            </Descriptions>
            <Button onClick={() => setTransactionModal(true)}>
              Корректировка
            </Button>
          </Fragment>
        )}
      </Space>
      <Divider />
      <Tabs
        defaultActiveKey={tab || 'all'}
        onTabClick={key => history.push(key)}
      >
        <TabPane tab="Отчёты" key="all">
          <ReportsTable dataSource={reports} />
        </TabPane>
        <TabPane tab="История транзакций" key="transactions">
          <TransactionsTable dataSource={transactions} />
        </TabPane>
      </Tabs>
      <Modal
        title="Корректировка"
        width={640}
        visible={transactionModal}
        onCancel={() => setTransactionModal(false)}
        footer={false}
      >
        {selectedOwner && (
          <Form
            form={form}
            component={false}
            onFinish={({ amount, description }) => {
              makeTransactionFx({
                amount,
                description,
                accountId: selectedOwner.id,
                onSuccess: () => {
                  form.resetFields()
                  setTransactionModal(false)
                },
              })
            }}
            {...formLayout}
          >
            <Form.Item label="Сумма" name="amount">
              <InputNumber
                placeholder="Введите сумму транзакции"
                disabled={transactionPending}
                style={{ width: '100%' }}
                step={0.01}
                onPressEnter={() => form.submit()}
              />
            </Form.Item>
            <Form.Item label="Комментарий" name="description">
              <Input placeholder="Комментарий к транзакции" />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Space>
                <Button
                  onClick={() => {
                    setTransactionModal(false)
                    form.resetFields()
                  }}
                >
                  Отмена
                </Button>
                <Button
                  type="primary"
                  disabled={transactionPending}
                  onClick={() => form.submit()}
                >
                  Подтвердить
                </Button>
              </Space>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </ScreenLayout>
  )
}

export default Reports
