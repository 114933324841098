import React from 'react'
import Table from 'antd/lib/table'
import Text from 'antd/lib/typography/Text'
import Tooltip from 'antd/lib/tooltip'
import List from 'antd/lib/list'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Space from 'antd/lib/space'
// import Alert from 'antd/lib/alert'
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined'
import CheckOutlined from '@ant-design/icons/CheckOutlined'
import CloseOutlined from '@ant-design/icons/CloseOutlined'
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined'
import moment from 'moment'
import { DAY_MONTH_FORMAT } from '../utils/constants'
import { formatPhoneNumber } from '../utils/helpers'

const OrdersTable = props => {
  const columns = [
    {
      title: 'Заказ',
      dataIndex: 'id',
      name: 'id',
      render: (_, { id }) => {
        return (
          <Text>
            <Text type="secondary">#</Text> <Text strong>{id}</Text>
          </Text>
        )
      },
    },
    {
      title: 'Дата',
      name: 'date',
      render: (_, { createdAt }) => {
        const date = moment(createdAt).format(DAY_MONTH_FORMAT)
        return <Text>{date}</Text>
      },
    },
    { title: 'Ресторан', dataIndex: 'shopTitle', name: 'shopTitle' },
    {
      title: 'Адрес',
      name: 'address',
      render: (_, { address_line, address }) => {
        return <Text>{address_line || address?.line || 'Ошибка адреса'}</Text>
      },
    },
    {
      title: 'Телефон',
      name: 'customer_contact',
      render: (_, { customer_contact }) => (
        <a href={`tel:${customer_contact}`}>
          {formatPhoneNumber(customer_contact)}
        </a>
      ),
    },
    {
      title: 'Сумма',
      name: 'sum',
      render: (_, { meta }) => (
        <Text>
          {meta?.sum}&nbsp;<Text type="secondary">&#8381;</Text>
        </Text>
      ),
    },
    {
      title: 'Доставка',
      name: 'deliveryCost',
      render: (_, { meta }) => (
        <Text>
          {meta?.deliveryCost}&nbsp;<Text type="secondary">&#8381;</Text>
        </Text>
      ),
    },
    {
      title: 'Итого',
      name: 'total',
      render: (_, { meta, payment_type }) => (
        <Text mark={payment_type === 1}>
          {Number(meta?.sum) + Number(meta?.deliveryCost)}&nbsp;
          <Text type="secondary">&#8381;</Text>
        </Text>
      ),
    },
    {
      title: (
        <Tooltip title="Статус">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: 'status',
      name: 'status',
      render: (_, { status }) => {
        const type =
          status === 'new'
            ? 'warning'
            : status === 'confirmed'
            ? 'success'
            : 'danger'

        const icon =
          status === 'new' ? (
            <PlusCircleOutlined />
          ) : status === 'confirmed' ? (
            <CheckOutlined />
          ) : (
            <CloseOutlined />
          )
        return <Text type={type}>{icon}</Text>
      },
    },
  ]

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {/* <Alert
        type="warning"
        message="Жёлтым цветом выделена стоимость заказа, оплаченного онлайн."
        style={{ marginBottom: 16 }}
      /> */}
      <Table
        {...props}
        columns={columns}
        scroll={{ x: true }}
        expandable={{
          expandedRowRender: ({ basket }) => {
            return (
              <List
                itemLayout="vertical"
                dataSource={basket}
                renderItem={({
                  id,
                  title,
                  qty,
                  price,
                  finalPrice,
                  options,
                }) => {
                  return (
                    <List.Item>
                      <Row gutter={16} style={{ width: '50%' }}>
                        <Col span={10}>
                          <Text>
                            {qty}&nbsp;&times;&nbsp;{title}
                          </Text>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right' }}>
                          <Text>
                            {finalPrice || price}&nbsp;
                            <Text type="secondary">&#8381;</Text>
                          </Text>
                        </Col>
                      </Row>
                      {options.map(
                        ({ id: optionId, title, price, finalPrice }) => {
                          return (
                            <Row
                              key={`${id}-${optionId}`}
                              gutter={16}
                              style={{ width: '50%' }}
                            >
                              <Col span={10}>
                                <Text type="secondary">-&nbsp;{title}</Text>
                              </Col>
                              <Col span={4} style={{ textAlign: 'right' }}>
                                <Text type="secondary">
                                  +&nbsp;{finalPrice || price}&nbsp;&#8381;
                                </Text>
                              </Col>
                            </Row>
                          )
                        },
                      )}
                    </List.Item>
                  )
                }}
              />
            )
          },
          rowExpandable: ({ basket }) => !!basket,
        }}
      />
    </Space>
  )
}

export default OrdersTable
