import React, { useEffect } from 'react'
import { useStore } from 'effector-react'
import { Select, Divider, Tabs, Skeleton } from 'antd'
import { useLocation, useHistory } from 'react-router-dom'

import ScreenLayout from '../../components/ScreenLayout'
import PageHeader from '../../components/PageHeader'
import Dishes from './MenusScreen/Dishes'
import DishCategories from './MenusScreen/DishCategories'
import DishOptions from './MenusScreen/DishOptions'
import DishOptionsGroups from './MenusScreen/DishOptionsGroups'
import { loadRestaurantsFx } from '../../models/restaurants'
import { loadDishesFx, $dishes } from '../../models/dishes'
import {
  loadDishCategoriesFx,
  $dishCategories,
} from '../../models/dishCategories'
import { loadDishOptionsFx, $dishOptions } from '../../models/dishOptions'
import {
  loadOptionGroupsFx,
  $optionGroups,
} from '../../models/dishOptionGroups'
import {
  $selectedRestaurantId,
  $filteredRestaurants,
  restaurantSelected,
  restaurantsSearched,
  restaurantSearchResetted,
} from '../../models/menus'

const { TabPane } = Tabs

function MenusScreen() {
  const restaurants = useStore($filteredRestaurants)
  const selectedRestaurantId = useStore($selectedRestaurantId)
  const restaurantsLoading = useStore(loadRestaurantsFx.pending)
  const dishes = useStore($dishes)
  const dishesLoading = useStore(loadDishesFx.pending)
  const dishCategories = useStore($dishCategories)
  const dishCategoriesLoading = useStore(loadDishCategoriesFx.pending)
  const dishOptions = useStore($dishOptions)
  const dishOptionsLoading = useStore(loadDishOptionsFx.pending)
  const optionGroups = useStore($optionGroups)
  const optionGroupsLoading = useStore(loadOptionGroupsFx.pending)
  const { pathname } = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (typeof selectedRestaurantId === 'number' && selectedRestaurantId > 0) {
      loadDishCategoriesFx(selectedRestaurantId)
      loadOptionGroupsFx(selectedRestaurantId)
      loadDishOptionsFx(selectedRestaurantId)
      loadDishesFx(selectedRestaurantId)
    }
  }, [selectedRestaurantId])

  return (
    <ScreenLayout>
      <PageHeader title="Меню" />
      <Select
        style={{ width: 320 }}
        size="large"
        showSearch
        loading={restaurantsLoading}
        placeholder="Выберите ресторан"
        value={selectedRestaurantId}
        onSearch={restaurantsSearched}
        onChange={restaurantSelected}
        onBlur={restaurantSearchResetted}
        filterOption={false}
        notFoundContent={null}
      >
        {restaurants.map(({ key, id, title }) => (
          <Select.Option key={key} value={id}>
            {title}
          </Select.Option>
        ))}
      </Select>
      <Divider />
      <Tabs defaultActiveKey={pathname} onTabClick={key => history.push(key)}>
        <TabPane tab="Блюда" key={'/menus'}>
          {dishesLoading && dishes.length === 0 ? (
            <Skeleton active />
          ) : (
            <Dishes />
          )}
        </TabPane>
        <TabPane tab="Категории блюд" key={'/menus/categories'}>
          {dishCategoriesLoading && dishCategories.length === 0 ? (
            <Skeleton active />
          ) : (
            <DishCategories />
          )}
        </TabPane>
        <TabPane tab="Опции" key={'/menus/options'}>
          {dishOptionsLoading && dishOptions.length === 0 ? (
            <Skeleton active />
          ) : (
            <DishOptions />
          )}
        </TabPane>
        <TabPane tab="Группы опций" key={'/menus/option-groups'}>
          {optionGroupsLoading && optionGroups.length === 0 ? (
            <Skeleton active />
          ) : (
            <DishOptionsGroups />
          )}
        </TabPane>
      </Tabs>
    </ScreenLayout>
  )
}

export default MenusScreen
