import React, { useEffect } from 'react'
import Form from 'antd/lib/form'
import Space from 'antd/lib/space'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input'
import formLayout, { tailLayout } from '../../../params/formLayout'
import { updateOwnerDetailsFx } from '../../../models/owners'
import ReactInputMask from 'react-input-mask'

const DetailsForm = ({ account }) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
  }, [account, form])

  function handleSubmit(values) {
    const contact = values.contact.replace(/[^0-9]/g, '')
    updateOwnerDetailsFx({ id: account.id, details: { ...values, contact } })
  }

  return account ? (
    <Form
      form={form}
      component={false}
      initialValues={account}
      onFinish={handleSubmit}
      {...formLayout}
    >
      <Form.Item label="Название" name="title" required>
        <Input placeholder="Название организации" />
      </Form.Item>
      <Form.Item label="ФИО" name="full_name" required>
        <Input placeholder="Полное имя владельца" />
      </Form.Item>
      <Form.Item label="Телефон" name="contact" required>
        <ReactInputMask mask="+7 (999) 999-99-99">
          <Input placeholder="Контактный номер" />
        </ReactInputMask>
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Space>
          <Button onClick={() => form.resetFields()}>Сброс</Button>
          <Button type="primary" onClick={() => form.submit()}>
            Сохранить
          </Button>
        </Space>
      </Form.Item>
    </Form>
  ) : null
}

export default DetailsForm
