import Axios from 'axios'
import { addAddressesFx, deleteAddressFx } from '.'
import { $token } from '../user'

async function getAddressWithCoordinates(addressLine) {
  let latitude = 0;
  let longitude = 0;

  try {
    await window.ymaps.geocode(`Элиста, ${addressLine}`).then(res => {
      const obj = res.geoObjects.get(0)
      const point = obj.geometry.getCoordinates()
      latitude = point[0]
      longitude = point[1]
    })
  } catch (e) {
    console.error('Не удалось получить координаты для адреса', e);
  }

  return { line: addressLine, latitude, longitude }
}

addAddressesFx.use(async ({ id, addressLine }) => {
  const address = await getAddressWithCoordinates(addressLine)
  const { data } = await Axios.post(
    `/api/admin/shops/${id}/address`,
    { address },
    { headers: { Authorization: `Bearer ${$token.getState()}` } },
  )
  return data
})

deleteAddressFx.use(async ({ shopId, addressId }) => {
  const { data } = await Axios.delete(
    `/api/admin/shops/${shopId}/address/${addressId}`,
    {
      headers: { Authorization: `Bearer ${$token.getState()}` },
    },
  )

  return data
})
