import { combine, sample } from 'effector'
import axios from 'axios'
import { $token } from '../user'
import { loadOrdersFx, $rawOrdersData, $orders, $ordersMeta } from './'
import { $restaurants } from '../restaurants'

loadOrdersFx.use(async ({ page = 1, perPage = 10, search }) => {
  const searchQuery = search ? `&search=${search}` : ''
  const queryString = `?page=${page}&perPage=${perPage}${searchQuery}`

  const { data } = await axios.get(`/api/admin/orders${queryString}`, {
    headers: {
      Authorization: `Bearer ${$token.getState()}`,
    },
  })

  if (data.code === 'ok') {
    return data
  } else {
    throw new Error('Не удалось загрузить заказы')
  }
})

$rawOrdersData.on(loadOrdersFx.done, (_, { result: { data } }) => data)
$ordersMeta.on(loadOrdersFx.done, (_, { result: { meta } }) => meta)

sample({
  source: combine({
    orders: $rawOrdersData,
    restaurants: $restaurants,
  }),
  fn: ({ orders, restaurants }) =>
    orders.map(order => {
      const {
        id,
        address,
        customer_address,
        takeaway,
        takeawayAddress,
        shop_id,
      } = order
      const key = `order-${id}`
      const shopTitle = restaurants.find(({ id }) => id === shop_id)?.title

      const address_line = takeaway
        ? `Самовывоз: ${takeawayAddress}`
        : address && address.line
        ? `${address.line}${address.office ? `, ${address.office}` : ''}`
        : customer_address || ''

      return { ...order, key, address_line, shopTitle }
    }),
  target: $orders,
})
