import axios from 'axios'
import { $token, userLoggedOut } from '../user'
import { loadPaymentTypesFx, $paymentTypes } from './'

loadPaymentTypesFx.use(async () => {
  const res = await axios.get('/api/paymenttypes', {
    headers: {
      Authorization: `Bearer ${$token.getState()}`,
    },
  })
  return res.data.data
})

loadPaymentTypesFx.fail.watch(({ error }) => {
  if (error.response.status) userLoggedOut()
})

$paymentTypes.on(loadPaymentTypesFx.done, (_, { result }) =>
  result.map(type => {
    const { id } = type
    const key = `payment-type-${id}`
    return { ...type, key }
  }),
)
