import React, { useEffect, useState } from 'react'
import { useStore } from 'effector-react'
import Space from 'antd/lib/space'
import Skeleton from 'antd/lib/skeleton'
import Divider from 'antd/lib/divider'
import { Area } from '@ant-design/charts'
import ScreenLayout from '../../components/ScreenLayout'
import PageHeader from '../../components/PageHeader'
import RestaurantSelect from './Stats/RestaurantSelect'
import PeriodSelect from './Stats/PeriodSelect'
import { $stats, loadStatsFx } from '../../models/stats'
import ChartTypeSelect from './Stats/ChartTypeSelect'

const Stats = () => {
  const stats = useStore($stats)
  const [period, setPeriod] = useState(undefined)
  const [restaurantId, setRestaurantId] = useState(undefined)
  const [chartType, setChartType] = useState('orders')
  const loading = useStore(loadStatsFx.pending)

  useEffect(() => {
    loadStatsFx({ restaurantId, period })
  }, [period, restaurantId])

  return (
    <ScreenLayout>
      <PageHeader title="Статистика" />
      <Space>
        <RestaurantSelect onSelect={setRestaurantId} />
        <PeriodSelect onChange={setPeriod} />
        <Divider type="vertical" />
        <ChartTypeSelect onChange={setChartType} />
      </Space>
      <Divider />
      {(restaurantId && period) || !loading ? (
        <Area
          xField="period"
          yField="value"
          seriesField="category"
          isStack={false}
          data={stats[chartType]}
        />
      ) : (
        <Skeleton loading={loading} />
      )}
    </ScreenLayout>
  )
}

export default Stats
