import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import history from './utils/history'

import App from './App'
import './models/init'

export const DEV = process.env.REACT_APP_ENV !== 'production'

DEV && console.log('DEVELOPMENT')

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById('root'),
)
