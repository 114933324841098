import React, { useEffect, useState } from 'react'
import Radio from 'antd/lib/radio'

const STATS_CHART_LSKEY = 'stats_chart'

const CHARTS = [
  { value: 'orders', label: 'Заказы' },
  { value: 'earning', label: 'Выручка' },
  { value: 'avrgCheck', label: 'Чек' },
  { value: 'canceled', label: 'Отменено' },
]

const ChartTypeSelect = ({ onChange = () => null }) => {
  const [value, setValue] = useState(CHARTS[0].value)

  useEffect(() => {
    const ls = localStorage.getItem(STATS_CHART_LSKEY)
    if (ls) {
      setValue(ls)
      onChange(ls)
    }
  }, [onChange])

  function handleChange(event) {
    const value = event.target.value
    localStorage.setItem(STATS_CHART_LSKEY, value)
    setValue(value)
    onChange(value)
  }

  return (
    <Radio.Group
      value={value}
      onChange={handleChange}
      options={CHARTS}
      optionType="button"
      buttonStyle="solid"
    />
  )
}

export default ChartTypeSelect
