import React, { useState } from 'react'
import styled from 'styled-components'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
} from 'react-router-dom'
import { Layout, Menu, Modal } from 'antd'
import {
  ShopOutlined,
  NotificationOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
  GiftOutlined,
  AppstoreOutlined,
  UsergroupAddOutlined,
  UnorderedListOutlined,
  VideoCameraOutlined,
  DollarOutlined,
  StopOutlined,
  LineChartOutlined,
  RetweetOutlined,
} from '@ant-design/icons'
import { userLoggedOut } from '../models/user'
import OrderScreens from './screens/OrdersScreen'
import RestaurantsScreens from './screens/RestaurantsScreen'
import MenusScreens from './screens/MenusScreen'
import RestaurantCategoriesScreens from './screens/RestaurantCategoriesScreens'
import OwnersScreen from './screens/OwnersScreen'
import PromocodesScreen from './screens/PromocodesScreen'
import StoriesScreen from './screens/StoriesScreen'
import Reports from './screens/Reports'
import BlackList from './screens/BlackList'
import Stats from './screens/Stats'
import Transactions from './screens/Transactions'

const Sider = styled(Layout.Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
`

const Content = styled(Layout.Content)`
  transition: margin-left 0.2s;
  transition-timing-function: ease;
`

function Dashboard() {
  const [collapsed, setCollapsed] = useState(true)
  const { screen } = useParams()

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  function openLogoutModal() {
    Modal.confirm({
      title: 'Выход',
      icon: <ExclamationCircleOutlined />,
      content: 'Вы действительно хотите выйти из панели управления?',
      okButtonProps: {
        danger: true,
      },
      okText: 'Выйти',
      cancelText: 'Отмена',
      onOk() {
        userLoggedOut()
      },
    })
  }

  return (
    <Router>
      <Layout>
        <Sider collapsible collapsed={collapsed} onCollapse={toggleCollapsed}>
          <Menu
            defaultSelectedKeys={screen || 'stats'}
            theme="dark"
            mode="vertical"
          >
            <Menu.Item key="stats" icon={<LineChartOutlined />}>
              <Link to="/stats">Статистика</Link>
            </Menu.Item>
            <Menu.Item key="restaurants" icon={<ShopOutlined />}>
              <Link to="/restaurants">Рестораны</Link>
            </Menu.Item>
            <Menu.Item key="menus" icon={<UnorderedListOutlined />}>
              <Link to="/menus">Меню ресторанов</Link>
            </Menu.Item>
            <Menu.Item key="restaurant-categories" icon={<AppstoreOutlined />}>
              <Link to="/restaurant-categories">Категории</Link>
            </Menu.Item>
            <Menu.Item key="promocodes" icon={<GiftOutlined />}>
              <Link to="/promocodes">Промокоды</Link>
            </Menu.Item>
            <Menu.Item key="stories" icon={<VideoCameraOutlined />}>
              <Link to="/stories">Истории</Link>
            </Menu.Item>
            <Menu.Item key="owners" icon={<UsergroupAddOutlined />}>
              <Link to="/owners">Владельцы</Link>
            </Menu.Item>
            <Menu.Item key="reports" icon={<DollarOutlined />}>
              <Link to="/reports/all">Отчёты</Link>
            </Menu.Item>
            <Menu.Item key="transactions" icon={<RetweetOutlined />}>
              <Link to="/transactions">Транзакции</Link>
            </Menu.Item>
            <Menu.Item key="blacklist" icon={<StopOutlined />}>
              <Link to="/blacklist">Чёрный список</Link>
            </Menu.Item>
            <Menu.Item key="orders" icon={<NotificationOutlined />}>
              <Link to="/orders">Заказы</Link>
            </Menu.Item>
          </Menu>
          <Menu theme="dark" mode="vertical" selectable={false}>
            <Menu.Item
              key="logout"
              icon={<LogoutOutlined />}
              danger
              onClick={openLogoutModal}
            >
              Выход
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Content style={{ marginLeft: collapsed ? 80 : 200 }}>
            <Switch>
              <Route exact path="/">
                <Redirect to="/stats" />
              </Route>
              <Route path="/stats">
                <Stats />
              </Route>
              <Route path="/orders">
                <OrderScreens />
              </Route>
              <Route path="/restaurants">
                <RestaurantsScreens />
              </Route>
              <Route path="/menus">
                <MenusScreens />
              </Route>
              <Route path="/restaurant-categories">
                <RestaurantCategoriesScreens />
              </Route>
              <Route path="/owners">
                <OwnersScreen />
              </Route>
              <Route path="/promocodes">
                <PromocodesScreen />
              </Route>
              <Route path="/stories">
                <StoriesScreen />
              </Route>
              <Route path="/blacklist">
                <BlackList />
              </Route>
              <Route path="/transactions">
                <Transactions />
              </Route>
              <Route path="/reports/:tab">
                <Reports />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Router>
  )
}

export default Dashboard
