import React, { useState } from 'react'
import { useStore } from 'effector-react'
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { $selectedRestaurantId } from '../../../models/menus'
import { $optionGroups } from '../../../models/dishOptionGroups'
import {
  $dishOptions,
  createDishOptionFx,
  updateDishOptionFx,
} from '../../../models/dishOptions'
import formLayout from '../../../params/formLayout'

const { Text } = Typography

function DishOptions() {
  const [editModal, setEditModal] = useState(false)
  const [editingKey, setEditingKey] = useState(0)
  const selectedRestaurantId = useStore($selectedRestaurantId)
  const optionGroups = useStore($optionGroups)
  const dishOptions = useStore($dishOptions)
  const [form] = Form.useForm()

  function onAddClick() {
    form.resetFields()
    setEditModal(true)
    setEditingKey(0)
  }

  function onEditClick(record) {
    form.setFieldsValue(record)
    setEditingKey(record.id)
    setEditModal(true)
  }

  async function onEditSaveClick() {
    const values = await form.validateFields()
    const shopId = selectedRestaurantId

    try {
      if (editingKey > 0) {
        updateDishOptionFx({
          shopId,
          optionId: editingKey,
          option: values,
        })
      } else {
        createDishOptionFx({
          shopId,
          option: values,
        })
      }
      setEditModal(false)
    } catch (e) {
      console.log('Validate Failed:', e)
      return e
    }
  }

  const columns = [
    {
      title: 'Название',
      dataIndex: 'title',
      name: 'title',
    },
    {
      title: 'Группа',
      dataIndex: 'groupDescription',
      name: 'group',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        if (a.group_id === b.group_id) {
          if (!a.position) return true
          if (!b.position) return false
          return a.position - b.position
        }
        return a.group_id - b.group_id
      },
    },
    {
      title: 'Стоимость',
      dataIndex: 'price',
      name: 'price',
    },
    {
      title: 'Позиция',
      name: 'position',
      render: (_, record) => {
        return (
          <Text type={!record.position && 'secondary'}>
            {record.position || 'Не указана'}
          </Text>
        )
      },
    },
    {
      title: 'Действия',
      name: 'actions',
      width: '10%',
      render: (_, record) => {
        return (
          <Button type="link" onClick={() => onEditClick(record)}>
            Изменить
          </Button>
        )
      },
    },
  ]

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        style={{ marginBottom: 16 }}
        onClick={onAddClick}
      >
        Добавить опцию
      </Button>
      <Table
        bordered
        dataSource={dishOptions}
        columns={columns}
        scroll={{ x: true }}
        pagination={{ showSizeChanger: true }}
      />
      <Modal
        title={editingKey > 0 ? 'Редактирование опции' : 'Добавление опции'}
        visible={editModal}
        centered
        okText="Сохранить"
        width={640}
        onOk={onEditSaveClick}
        onCancel={() => setEditModal(false)}
      >
        <Form {...formLayout} form={form} component={false}>
          <Form.Item
            label="Название"
            name="title"
            rules={[
              {
                required: true,
                message: 'Обязательное поле!',
              },
            ]}
          >
            <Input placeholder="Введите название опции" />
          </Form.Item>
          <Form.Item
            label="Группа"
            name="group_id"
            rules={[
              {
                required: true,
                message: 'Обязательное поле!',
              },
            ]}
          >
            <Select placeholder="Выберите группу">
              {optionGroups.map(option => {
                const { id, key, description } = option
                return (
                  <Select.Option key={`select-${key}`} value={id}>
                    {description}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Стоимость"
            name="price"
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <InputNumber
              min={0}
              placeholder="Стоимость опции в ₽"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item label="Позиция" name="position">
            <InputNumber
              min={1}
              placeholder="Укажите позцию опции"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default DishOptions
