import React, { useEffect, useState } from 'react'
import { useStore } from 'effector-react'
import moment from 'moment'
import Form from 'antd/lib/form/Form'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Space from 'antd/lib/space'
import Divider from 'antd/lib/divider'
import Text from 'antd/lib/typography/Text'
import DatePicker from 'antd/lib/date-picker'
import ReactInputMask from 'react-input-mask'
import formLayout, { tailLayout } from '../params/formLayout'
import { $restaurants, updateRestaurantFx } from '../models/restaurants'

const INN_REGEX = /^[\d]{10}$|^[\d]{12}$/
const OGRN_REGEX = /^[\d]{13}$|^[\d]{15}$/
const NINE_DIGITS_REGEX = /^[\d]{9}$/
const TWENTY_DIGITS_REGEX = /^[\d]{20}$/

const CustomDivider = ({ children }) => {
  return (
    <Divider>
      {children ? (
        <Text type="secondary" style={{ fontWeight: 400 }}>
          {children}
        </Text>
      ) : null}
    </Divider>
  )
}

const RestaurantInfoForm = ({ shopId = 0 }) => {
  const [form] = Form.useForm()
  const restaurants = useStore($restaurants)
  const [restaurant, setRestaurant] = useState(null)
  const pending = useStore(updateRestaurantFx.pending)

  useEffect(() => () => setRestaurant(null), [])

  useEffect(() => {
    if (shopId > 0) {
      const restaurant = restaurants.find(({ id }) => id === shopId)

      if (restaurant) {
        const contractDate = moment(restaurant.contract_start_date).isValid()
          ? moment(restaurant.contract_start_date)
          : null

        setRestaurant({ ...restaurant, contract_start_date: contractDate })
      }
    } else {
      setRestaurant(null)
    }
  }, [shopId, restaurants])

  useEffect(() => {
    if (restaurant) {
      form.setFieldsValue(restaurant)
    } else {
      form.resetFields()
    }
  }, [restaurant, form])

  return restaurant ? (
    <Form
      form={form}
      component={false}
      initialValues={restaurant}
      onFinish={values => {
        const contractDate = moment(values.contract_start_date)
          .hour(12)
          .minute(0)
          .second(0)

        updateRestaurantFx({
          ...restaurant,
          ...values,
          contract_start_date: contractDate,
        })
      }}
      {...formLayout}
    >
      <Form.Item
        label="Юр. название"
        name="legal_name"
        rules={[{ required: true }]}
      >
        <Input placeholder="Название организации" />
      </Form.Item>
      <Form.Item
        label="Юр. адрес"
        name="legal_address"
        rules={[{ required: true }]}
      >
        <Input placeholder="Адрес организации" />
      </Form.Item>
      <Form.Item
        label="Почтовый адрес"
        name="mailing_address"
        rules={[{ required: true }]}
      >
        <Input placeholder="Почтовый адрес организации" />
      </Form.Item>
      <Form.Item
        label="Номер телефона"
        name="accounting_phone"
        rules={[{ required: true }]}
      >
        <ReactInputMask mask={'+7 (999) 999-99-99'}>
          <Input placeholder="Номер телефона бухгалтерии" />
        </ReactInputMask>
      </Form.Item>
      <Form.Item label="Документ основание" name="foundation_document_type">
        <Input placeholder="Тип документа" />
      </Form.Item>
      <Form.Item label="Сведения о документе" name="foundation_document_info">
        <Input placeholder="Сведения о документе основании" />
      </Form.Item>

      <CustomDivider>Договор</CustomDivider>

      <Form.Item
        label="Номер договора"
        name="contract_number"
        rules={[{ required: true }]}
      >
        <Input placeholder="Номер договора" />
      </Form.Item>
      <Form.Item
        label="Дата договора"
        name="contract_start_date"
        rules={[{ type: 'object', required: true, message: 'false' }]}
      >
        <DatePicker
          format="DD.MM.YYYY"
          disabledDate={current => current && current > moment().endOf('day')}
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item
        label="Имя подписанта"
        name="signatory_name"
        rules={[{ required: true }]}
      >
        <Input placeholder="Имя подписанта" />
      </Form.Item>
      <Form.Item label="Должность подписанта" name="signatory_position">
        <Input placeholder="Должность подписанта" />
      </Form.Item>

      <CustomDivider>Реквизиты</CustomDivider>

      <Form.Item
        label="ИНН"
        name="inn"
        rules={[{ pattern: INN_REGEX, message: false }, { required: true }]}
      >
        <Input placeholder="от 10 до 12 цифр" />
      </Form.Item>
      <Form.Item
        label="КПП"
        name="kpp"
        rules={[{ pattern: NINE_DIGITS_REGEX, message: false }]}
      >
        <Input placeholder="9 цифр" />
      </Form.Item>
      <Form.Item
        label="БИК"
        name="bik"
        rules={[{ pattern: NINE_DIGITS_REGEX, message: false }]}
      >
        <Input placeholder="9 цифр" />
      </Form.Item>
      <Form.Item
        label="ОГРН"
        name="ogrn"
        rules={[{ pattern: OGRN_REGEX, message: false }]}
      >
        <Input placeholder="13 цифр" />
      </Form.Item>
      <Form.Item
        label="Расчётный счёт"
        name="checking_account"
        rules={[
          { pattern: TWENTY_DIGITS_REGEX, message: false },
          { required: true },
        ]}
      >
        <Input placeholder="20 цифр" />
      </Form.Item>
      <Divider />
      <Form.Item {...tailLayout}>
        <Space>
          <Button onClick={() => form.resetFields()} disabled={pending}>
            Сброс
          </Button>
          <Button
            type="primary"
            onClick={() => form.submit()}
            disabled={pending}
          >
            Сохранить
          </Button>
        </Space>
      </Form.Item>
    </Form>
  ) : (
    <span>Загрузка...</span>
  )
}

export default RestaurantInfoForm
