import axios from 'axios'
import {
  tokenKey,
  localTokenSetted,
  userLoggedOut,
  $token,
  loginFx,
  $loginFailMessage,
} from './'
import { loadRestaurantsFx } from '../restaurants'
import { loadPaymentTypesFx } from '../paymentTypes'
import { loadRestaurantCategoriesFx } from '../restaurantCategories'
import { loadOwnersFx } from '../owners'
import { loadPromocodesFx } from '../promocodes'

loginFx.use(async ({ login, password }) => {
  const res = await axios.post('/api/admin/login', {
    login: login.trim(),
    password,
  })
  return res.data.token
})

$token
  .on(localTokenSetted, (_, token) => token)
  .on(loginFx.done, (_, { result }) => result)
  .reset(userLoggedOut)

$token.updates.watch(token => {
  if (token) {
    localStorage.setItem(tokenKey, JSON.stringify(token))
    loadRestaurantsFx()
    loadPaymentTypesFx()
    loadRestaurantCategoriesFx()
    loadOwnersFx()
    loadPromocodesFx()
  }
})

$loginFailMessage.on(loginFx.fail, (_, { error }) => {
  console.log(error.response.status)
  console.log(error.message)
  return 'Произошла ошибка. Проверьте правильность введённых данных или попробуйте повторить позднее.'
})

try {
  const token = JSON.parse(localStorage.getItem(tokenKey))
  if (!token) {
    throw new Error('no local token')
  }
  localTokenSetted(token)
} catch (e) {
  console.log(e)
}
