import { createEffect, createStore } from 'effector'

export const loadDishesFx = createEffect()
export const createDishFx = createEffect()
export const updateDishFx = createEffect()
export const deleteDishFx = createEffect()
export const setAllInStockFx = createEffect()
export const uploadCsvFx = createEffect()
export const $rawDishesData = createStore([])
export const $dishes = createStore([])
export const $dishActionPending = createStore(false)
