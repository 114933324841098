import styled from 'styled-components'
import { PageHeader as Header } from 'antd'

const PageHeader = styled(Header)`
  margin-bottom: 24px;
  padding: 0;

  .ant-page-header-heading-title {
    font-size: 32px;
  }
`

export default PageHeader
