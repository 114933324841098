import { useStore } from 'effector-react'
import React, { useEffect, useState } from 'react'
import PageHeader from '../../components/PageHeader'
import ScreenLayout from '../../components/ScreenLayout'
import {
  $allTransactions,
  $allTransactionsMeta,
  loadAllTransactionsFx,
} from '../../models/transactions'
import AllTransactionsTable from './Transactions/AllTransactionsTable'

const Transactions = () => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const allTransactions = useStore($allTransactions)
  const meta = useStore($allTransactionsMeta)
  const loading = useStore(loadAllTransactionsFx.pending)

  useEffect(() => {
    if (meta?.totalPages < page) {
      setPage(1)
    }
  }, [meta, page])

  useEffect(() => {
    loadAllTransactionsFx({ page, perPage })
  }, [page, perPage])

  return (
    <ScreenLayout>
      <PageHeader title="Транзакции" />
      <AllTransactionsTable
        dataSource={allTransactions}
        loading={loading}
        pagination={{
          current: page,
          showSizeChanger: true,
          total: meta?.totalResults,
          pageSizeOptions: [10, 20, 30],
          onChange: (page, pageSize) => {
            setPage(page)
            setPerPage(pageSize)
          },
          pageSize: perPage,
        }}
      />
    </ScreenLayout>
  )
}

export default Transactions
