export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_AND_TIME_FORMAT = 'DD.MM.YYYY HH:mm'
export const DAY_MONTH_FORMAT = 'DD.MM.YY HH:mm'
export const PHONE_MASK = '+7 (999) 999-99-99'
export const PHONE_MASK_LENGTH = PHONE_MASK.replace(/\D+/g, '').length
export const DEFAULT_RESTAURANT_SCHEDULE = {
  0: {
    from: '00:00',
    to: '23:59',
    enabled: true,
  },
  1: {
    from: '00:00',
    to: '23:59',
    enabled: true,
  },
  2: {
    from: '00:00',
    to: '23:59',
    enabled: true,
  },
  3: {
    from: '00:00',
    to: '23:59',
    enabled: true,
  },
  4: {
    from: '00:00',
    to: '23:59',
    enabled: true,
  },
  5: {
    from: '00:00',
    to: '23:59',
    enabled: true,
  },
  6: {
    from: '00:00',
    to: '23:59',
    enabled: true,
  },
}
