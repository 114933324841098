import { sample } from 'effector'
import axios from 'axios'
import { $token } from '../user'
import {
  loadDishCategoriesFx,
  createDishCategoryFx,
  updateDishCategoryFx,
  $rawDishCategories,
  $dishCategories,
} from './'

loadDishCategoriesFx.use(async shopId => {
  const res = await axios.get(`/api/owner/shops/${shopId}/categories`, {
    headers: {
      Authorization: `Bearer ${$token.getState()}`,
    },
  })
  return res.data.data
})

createDishCategoryFx.use(async ({ shopId, values }) => {
  const res = await axios.post(
    `/api/owner/shops/${shopId}/categories/`,
    { ...values },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${$token.getState()}`,
      },
    },
  )
  return res.data.data
})

updateDishCategoryFx.use(async ({ shopId, categoryId, values }) => {
  const res = await axios.put(
    `/api/owner/shops/${shopId}/categories/${categoryId}`,
    { ...values },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${$token.getState()}`,
      },
    },
  )
  return res.data.data
})

$rawDishCategories
  .on(loadDishCategoriesFx.done, (_, { result }) => result)
  .on(createDishCategoryFx.done, (categories, { result }) => [
    ...categories,
    result,
  ])
  .on(updateDishCategoryFx.done, (categories, { result }) =>
    categories.map(category => (category.id === result.id ? result : category)),
  )

sample({
  source: $rawDishCategories,
  fn: rawDishCategories =>
    rawDishCategories.map(category => ({
      ...category,
      key: `dish-category-${category.id}`,
    })),
  target: $dishCategories,
})
