import axios from 'axios'
import message from 'antd/lib/message'
import { loadBlackListFx, banCustomerFx, unbanCustomerFx, $blacklist } from '.'
import { $token } from '../user'

loadBlackListFx.use(async () => {
  const { data } = await axios.get('/api/admin/global_bans', {
    headers: { Authorization: `Beader ${$token.getState()}` },
  })

  return data
})

banCustomerFx.use(async phone => {
  const { data } = await axios.put(
    '/api/admin/global_bans/add',
    { phone },
    { headers: { Authorization: `Beader ${$token.getState()}` } },
  )

  if (data.code === 'error') {
    message.error(data.message)
    throw new Error(data.message)
  }

  if (data.code === 'ok') {
    message.success('Пользователь заблокирован')
    return data.data
  }
})

unbanCustomerFx.use(async phone => {
  const { data } = await axios.put(
    '/api/admin/global_bans/remove',
    { phone },
    { headers: { Authorization: `Beader ${$token.getState()}` } },
  )

  return data
})

$blacklist
  .on(loadBlackListFx.done, (_, { result }) => {
    if (result.code === 'ok') {
      return result.data
    }
  })
  .on(banCustomerFx.done, (_, { result }) => {
    return result
  })
  .on(unbanCustomerFx.done, (_, { result }) => {
    if (result.code === 'ok') {
      return result.data
    }
  })
