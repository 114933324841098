import React, { useEffect, useState } from 'react'
import Radio from 'antd/lib/radio'

const STATS_PERIOD_LSKEY = 'stats_period'

const PERIODS = [
  { value: 'day', label: 'По дням' },
  { value: 'week', label: 'По неделям' },
  { value: 'month', label: 'По месяцам' },
]

const PeriodSelect = ({ onChange = () => null }) => {
  const [value, setValue] = useState(PERIODS[0].value)

  useEffect(() => {
    const ls = localStorage.getItem(STATS_PERIOD_LSKEY)
    setValue(ls)
  }, [])

  useEffect(() => {
    localStorage.setItem(STATS_PERIOD_LSKEY, value)
    onChange(value)
  }, [value, onChange])

  return (
    <Radio.Group
      value={value}
      onChange={({ target: { value } }) => setValue(value)}
      options={PERIODS}
      optionType="button"
    />
  )
}

export default PeriodSelect
