import React, { useState, useRef } from 'react'
import { useStore } from 'effector-react'
import {
  Table,
  Modal,
  Form,
  Button,
  Input,
  Typography,
  Tooltip,
  Row,
  Col,
  Checkbox,
  Dropdown,
  Menu,
  Space,
} from 'antd'
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  FieldTimeOutlined,
  EllipsisOutlined,
  ScheduleOutlined,
  EditOutlined,
  ShopOutlined,
  InfoCircleOutlined,
  UpCircleOutlined,
} from '@ant-design/icons'

import PageHeader from '../../components/PageHeader'
import ScreenLayout from '../../components/ScreenLayout'
import AddressesForm from '../../components/AddressesForm'
import {
  $restaurantFormModal,
  $restaurants,
  launchRestaurantFx,
  loadRestaurantsFx,
  restaurantFormModalSet,
  updateRestaurantFx,
} from '../../models/restaurants'
import getColumnSearchProps from '../../params/columnSearchProps'
import formLayout from '../../params/formLayout'
import RestaurantInfoForm from '../../components/RestaurantInfoForm'
import RestaurantForm from './RestaurantsScreen/RestaurantForm'

const { Text } = Typography

function RestaurantsScreen() {
  const [editingKey, setEditingKey] = useState(0)
  const [infoModal, setInfoModal] = useState(false)
  const [scheduleModal, setScheduleModal] = useState(false)
  const [addressesModal, setAddressesModal] = useState(false)
  const [editingRestaurant, setEditingRestaurant] = useState(null)
  const restaurants = useStore($restaurants)
  const loading = useStore(loadRestaurantsFx.pending)
  const restauratnFormModal = useStore($restaurantFormModal)
  const [scheduleForm] = Form.useForm()
  const ownerSearchRef = useRef()
  const titleSearchRef = useRef()
  const contactSearchRef = useRef()

  function onAddClick() {
    restaurantFormModalSet(true)
    setEditingRestaurant(null)
  }

  function onEditClick(record) {
    restaurantFormModalSet(true)
    setEditingRestaurant(record)
  }

  function onScheduleClick(record) {
    setEditingKey(record.id)
    scheduleForm.setFieldsValue(record.work_time)
    setScheduleModal(true)
  }

  function onInfoClick(record) {
    setEditingRestaurant(record)
    setInfoModal(true)
  }

  function onAddressesClick(record) {
    setEditingRestaurant(record)
    setAddressesModal(true)
  }

  async function onScheduleSave() {
    try {
      const workTime = await scheduleForm.validateFields()
      const restaurant = restaurants.find(({ id }) => editingKey === id)
      const restaurantWithUpdatedSchedule = {
        ...restaurant,
        work_time: workTime,
      }
      updateRestaurantFx(restaurantWithUpdatedSchedule)
      setEditingKey(0)
      setScheduleModal(false)
      scheduleForm.resetFields()
    } catch (errInfo) {
      console.error('Ошибка валидации:', errInfo)
    }
  }

  const columns = [
    {
      title: 'Владелец',
      render: (_, { ownerEmail }) => {
        return <Text>{ownerEmail}</Text>
      },
      ...getColumnSearchProps(
        'ownerEmail',
        'Поиск по логину владельца',
        ownerSearchRef,
      ),
    },
    {
      title: 'Название',
      dataIndex: 'title',
      ...getColumnSearchProps('title', 'Поиск по названию', titleSearchRef),
    },
    {
      title: (
        <Tooltip title="Среднее время доставки">
          <FieldTimeOutlined />
        </Tooltip>
      ),
      dataIndex: 'avg_delivery_time',
    },
    {
      title: 'Контакт',
      render: (_, { contact }) => {
        return <a href={`tel:${contact}`}>{contact}</a>
      },
      ...getColumnSearchProps('contact', 'Поиск по контакту', contactSearchRef),
    },
    {
      title: 'Комиссия',
      render: (_, { commission }) => {
        return <Text>{commission}&nbsp;%</Text>
      },
    },
    {
      title: 'Кешбек',
      render: (_, { bonusPercent }) => {
        const type = bonusPercent === 0 ? 'secondary' : ''
        return <Text type={type}>{bonusPercent}&nbsp;%</Text>
      },
    },
    {
      title: 'Запущен',
      render: (_, { enabled }) => {
        const color = enabled ? '#52c41a' : '#eb2f96'
        const icon = enabled ? (
          <CheckCircleTwoTone twoToneColor={color} />
        ) : (
          <CloseCircleTwoTone twoToneColor={color} />
        )
        return icon
      },
      sorter: (a, b) => a.enabled - b.enabled,
    },
    {
      title: 'Доступен',
      render: (_, { available }) => {
        const color = available ? '#52c41a' : '#eb2f96'
        const icon = available ? (
          <CheckCircleTwoTone twoToneColor={color} />
        ) : (
          <CloseCircleTwoTone twoToneColor={color} />
        )
        return icon
      },
      sorter: (a, b) => a.available - b.available,
    },
    {
      width: 'min-content',
      render: (_, record) => {
        return (
          <Space>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => onEditClick(record)}
            />
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    icon={<InfoCircleOutlined />}
                    onClick={() => onInfoClick(record)}
                  >
                    Информация
                  </Menu.Item>
                  <Menu.Item
                    icon={<ScheduleOutlined />}
                    onClick={() => onScheduleClick(record)}
                  >
                    График
                  </Menu.Item>
                  <Menu.Item
                    icon={<ShopOutlined />}
                    onClick={() => onAddressesClick(record)}
                  >
                    Адреса точек
                  </Menu.Item>
                  <Menu.Item
                    icon={<UpCircleOutlined />}
                    onClick={() =>
                      Modal.confirm({
                        title: <Text>Поднять {record.title}</Text>,
                        icon: <UpCircleOutlined />,
                        content: `Этот ресторан будет поднят вверх списка ресторанов.`,
                        okText: 'Подтвердить',
                        onOk: () => launchRestaurantFx(record.id),
                      })
                    }
                  >
                    Поднять в топ
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
              trigger={['click']}
            >
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          </Space>
        )
      },
    },
  ]

  return (
    <ScreenLayout>
      <PageHeader title="Рестораны" />
      <Button type="primary" style={{ marginBottom: 16 }} onClick={onAddClick}>
        Добавить ресторан
      </Button>
      <Table
        columns={columns}
        dataSource={restaurants}
        loading={loading}
        scroll={{ x: true }}
        pagination={{ showSizeChanger: true }}
        bordered
      />
      <Modal
        title={`${
          editingRestaurant > 0 ? 'Редактирование' : 'Добавление'
        } ресторана`}
        centered
        width={640}
        visible={restauratnFormModal}
        footer={false}
        onCancel={() => restaurantFormModalSet(false)}
      >
        <RestaurantForm id={editingRestaurant?.id} />
      </Modal>
      <Modal
        title="Редактирование графика"
        centered
        width={640}
        closable={false}
        visible={scheduleModal}
        okText="Сохранить"
        onOk={onScheduleSave}
        onCancel={() => setScheduleModal(false)}
      >
        <Form {...formLayout} form={scheduleForm} component={false}>
          {['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'].map((day, index) => (
            <Row justify="center" key={`day=${index}`}>
              <Col flex="80px">
                <Form.Item
                  name={[String(index), 'enabled']}
                  valuePropName="checked"
                >
                  <Checkbox>{day}</Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Input.Group compact>
                  <Form.Item
                    name={[String(index), 'from']}
                    rules={[
                      {
                        required: true,
                        pattern: /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/,
                        message: 'Введите правильное время открытия',
                      },
                    ]}
                    style={{ width: 110 }}
                  >
                    <Input placeholder="HH:MM" style={{ width: 110 }} />
                  </Form.Item>
                  <Input
                    style={{
                      width: 30,
                      margin: '0px 1px',
                      border: 'none',
                      pointerEvents: 'none',
                    }}
                    placeholder="&mdash;"
                  />
                  <Form.Item
                    name={[String(index), 'to']}
                    rules={[
                      {
                        required: true,
                        pattern: /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/,
                        message: 'Введите правильное время закрытия',
                      },
                    ]}
                    style={{ width: 110 }}
                  >
                    <Input placeholder="HH:MM" style={{ width: 110 }} />
                  </Form.Item>
                </Input.Group>
              </Col>
            </Row>
          ))}
        </Form>
      </Modal>

      <Modal
        title={editingRestaurant?.title + ': информация'}
        width={760}
        visible={infoModal}
        onCancel={() => setInfoModal(false)}
        footer={false}
      >
        {editingRestaurant ? (
          <RestaurantInfoForm shopId={editingRestaurant.id} />
        ) : null}
      </Modal>

      <Modal
        title={editingRestaurant?.title + ': адреса'}
        width={480}
        visible={addressesModal}
        onCancel={() => setAddressesModal(false)}
        footer={false}
      >
        {editingRestaurant ? (
          <AddressesForm shopId={editingRestaurant.id} />
        ) : null}
      </Modal>
    </ScreenLayout>
  )
}

export default RestaurantsScreen
