import React, { useState } from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'
import {
  Button,
  Table,
  Modal,
  Form,
  Input,
  Select,
  InputNumber,
  DatePicker,
  Checkbox,
  Tooltip,
  Typography,
  Switch,
  Space,
} from 'antd'
import {
  EditOutlined,
  UsergroupAddOutlined,
  PercentageOutlined,
  PlusSquareOutlined,
  CopyOutlined,
  TagOutlined,
  AppstoreOutlined,
  LoginOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from '@ant-design/icons'
import InputMask from 'react-input-mask'
import moment from 'moment'
import passfather from 'passfather'

import PageHeader from '../../components/PageHeader'
import ScreenLayout from '../../components/ScreenLayout'
import { $restaurants } from '../../models/restaurants'
import {
  $promocodes,
  loadPromocodesFx,
  createPromocodeFx,
  updatePromocodeFx,
} from '../../models/promocodes'
import {
  PROMOCODE_CONFIG,
  fastPromoConfig,
  fastPromos,
  copyToClipBoard,
} from './PromocodesScreen/helpers'
import formLayout, { tailLayout } from '../../params/formLayout'
import { PHONE_MASK, DATE_FORMAT } from '../../utils/constants'

const ButtonSpace = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -16px;

  & > * {
    margin-left: 16px;
    margin-bottom: 16px;
  }
`

const { Text } = Typography

function RestaurantsScreen() {
  const [editModal, setEditModal] = useState(false)
  const [editingKey, setEditingKey] = useState(0)
  const restaurants = useStore($restaurants)
  const promocodes = useStore($promocodes)
  const loading = useStore(loadPromocodesFx.pending)
  const updatePending = useStore(updatePromocodeFx.pending)
  const [form] = Form.useForm()

  function onAddClick() {
    form.resetFields()
    setEditingKey(0)
    setEditModal(true)
  }

  function onEditClick(record) {
    const dateFrom = record.from ? moment(record.from, DATE_FORMAT) : undefined
    const dateTo = record.to ? moment(record.to, DATE_FORMAT) : undefined

    form.setFieldsValue({
      ...record,
      fromTo: [dateFrom, dateTo],
    })

    setEditingKey(record.id)
    setEditModal(true)
  }

  function generatePromocode(discount, minSum, days) {
    const startDate = moment()
      .subtract(1, 'days')
      .set({ hour: 3, minute: 0, second: 0, millisecond: 0 })
      .toString()
    const endDate = moment()
      .set({ hour: 3, minute: 0, second: 0, millisecond: 0 })
      .add(days, 'days')
      .toString()

    createPromocodeFx({
      ...fastPromoConfig,
      code: passfather(PROMOCODE_CONFIG),
      discount: discount,
      minOrderSum: minSum,
      from: startDate,
      to: endDate,
    })
  }

  function onActiveToggle(record) {
    const newRecord = { ...record, active: !record.active }
    updatePromocodeFx(newRecord)
  }

  const onEditSave = async key => {
    try {
      const values = await form.validateFields()
      values.code = values.code.toLowerCase()

      values.from =
        values.fromTo && values.fromTo[0]
          ? values.fromTo[0]
              .set({ hour: 3, minute: 0, second: 0, millisecond: 0 })
              .toString()
          : null

      values.to =
        values.fromTo && values.fromTo[1]
          ? values.fromTo[1]
              .set({ hour: 3, minute: 0, second: 0, millisecond: 0 })
              .toString()
          : null

      if (values.customer_phone) {
        values.customer_phone = values.customer_phone.replace(/\D+/g, '')
      }

      if (key > 0) {
        values.id = key
        updatePromocodeFx(values)
      } else {
        createPromocodeFx(values)
      }
      setEditModal(false)
      return true
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
      return errInfo
    }
  }

  const columns = [
    {
      title: 'Промокод',
      dataIndex: 'code',
      key: 'code',
      render: (_, record) => (
        <Text
          code
          type={!record.active && 'secondary'}
          style={{ letterSpacing: '0.125em' }}
        >
          {record.code.toUpperCase()}
        </Text>
      ),
    },
    {
      title: (
        <Tooltip title="Размер скидки в ₽">
          <PercentageOutlined />
        </Tooltip>
      ),
      dataIndex: 'discount',
      key: 'discount',
      render: (_, record) => (
        <Text type={!record.active && 'secondary'}>{record.discount}</Text>
      ),
    },
    {
      title: (
        <Tooltip title="Минимальная сумма заказа">
          <TagOutlined />
        </Tooltip>
      ),
      dataIndex: 'minOrderSum',
      key: 'minOrderSum',
      render: (_, record) => (
        <Text type={!record.active && 'secondary'}>
          {record.minOrderSum || 0}
        </Text>
      ),
    },
    {
      title: (
        <Tooltip title="Количество использований">
          <UsergroupAddOutlined />
        </Tooltip>
      ),
      dataIndex: 'count',
      key: 'count',
      render: (_, record) => {
        return (
          <Text type={!record.active && 'secondary'}>
            {`${record.count || 0}`}
            {record.limit && ` / ${record.limit}`}
          </Text>
        )
      },
    },
    {
      title: 'Ресторан',
      dataIndex: 'shop_id',
      key: 'shop_id',
      render: (_, record) => (
        <Text type={!record.active && 'secondary'}>
          {record.restaurant ? record.restaurant.title : 'Для всех ресторанов'}
        </Text>
      ),
    },
    {
      title: 'Пользователь',
      dataIndex: 'customer_phone',
      key: 'customer_phone',
      render: (_, record) => (
        <Text type={!record.active && 'secondary'}>
          {record.customer_phone || 'Не указан'}
        </Text>
      ),
    },
    {
      title: 'Действителен до',
      dataIndex: 'to',
      key: 'to',
      render: (_, record) => (
        <Tooltip
          title={
            record.from &&
            `Начиная с ${moment(record.from).format('DD.MM.YYYY')}`
          }
        >
          <Text type={!record.active && 'secondary'}>
            {record.to ? moment(record.to).format('DD.MM.YYYY') : 'Бессрочно'}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
      key: 'comment',
      render: (_, record) => (
        <Text type={!record.active && 'secondary'}>
          {record.comment || 'Нет комментария'}
        </Text>
      ),
    },
    {
      title: (
        <Tooltip title="Только для мобильного приложения">
          <AppstoreOutlined />
        </Tooltip>
      ),
      dataIndex: 'mobile',
      key: 'mobile',
      render: (_, record) =>
        record.mobile ? (
          <CheckCircleTwoTone
            twoToneColor={!record.active ? '#8c8c8c' : '#52c41a'}
          />
        ) : (
          <CloseCircleTwoTone
            twoToneColor={!record.active ? '#8c8c8c' : '#ff4d4f'}
          />
        ),
    },
    {
      title: (
        <Tooltip title="Только на первый заказ">
          <LoginOutlined />
        </Tooltip>
      ),
      dataIndex: 'first',
      key: 'first',
      render: (_, record) =>
        record.first ? (
          <CheckCircleTwoTone
            twoToneColor={!record.active ? '#8c8c8c' : '#52c41a'}
          />
        ) : (
          <CloseCircleTwoTone
            twoToneColor={!record.active ? '#8c8c8c' : '#ff4d4f'}
          />
        ),
    },
    {
      title: 'Действия',
      render: (_, record) => (
        <Space size="middle" align="center">
          <Tooltip title={record.active ? 'Отключить' : 'Включить'}>
            <Switch
              loading={updatePending}
              checked={record.active}
              onChange={() => onActiveToggle(record)}
            />
          </Tooltip>
          <Tooltip title="Редактировать">
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                onEditClick(record)
              }}
            />
          </Tooltip>
          <Tooltip title="Скопировать информацию">
            <Button
              icon={<CopyOutlined />}
              onClick={() => copyToClipBoard(record)}
            />
          </Tooltip>
        </Space>
      ),
      sorter: (a, b) => a.active - b.active,
      showSorterTooltip: false,
    },
  ]

  return (
    <ScreenLayout>
      <PageHeader title="Промокоды" />
      <ButtonSpace>
        <Button type="primary" onClick={onAddClick} disabled={loading}>
          Добавить промокод
        </Button>
        {fastPromos.map(({ discount, minSum, duration }, index) => (
          <Button
            key={`fast-promo-button-${index + 1}`}
            icon={<PlusSquareOutlined />}
            onClick={() => generatePromocode(discount, minSum, duration)}
            disabled={loading}
          >
            {discount}&nbsp;₽
          </Button>
        ))}
      </ButtonSpace>
      <Table
        columns={columns}
        dataSource={promocodes}
        loading={loading}
        scroll={{ x: true }}
        pagination={{ showSizeChanger: true }}
        bordered
      />
      <Modal
        title={editingKey > 0 ? 'Редактирование товара' : 'Добавление товара'}
        visible={editModal}
        centered
        closable={false}
        cancelText="Отмена"
        okText="Сохранить"
        onCancel={() => setEditModal(false)}
        onOk={() => onEditSave(editingKey)}
        width={640}
      >
        <Form {...formLayout} form={form} component={false}>
          <Form.Item label="Промокод" name="code" required>
            <Input
              placeholder="Введите название промокода"
              disabled={editingKey > 0}
            />
          </Form.Item>
          <Form.Item label="Размер скидки в ₽" name="discount" required>
            <InputNumber
              min={0}
              style={{ width: '100%' }}
              placeholder="Сумма в рублях"
            />
          </Form.Item>
          <Form.Item label="Мин. сумма в ₽" name="minOrderSum">
            <InputNumber
              min={0}
              style={{ width: '100%' }}
              placeholder="Сумма в рублях"
            />
          </Form.Item>
          <Form.Item label="Лимит" name="limit">
            <InputNumber
              min={0}
              style={{ width: '100%' }}
              placeholder="Введите лимит использований"
            />
          </Form.Item>
          <Form.Item label="Ресторан" name="shop_id">
            <Select placeholder="Выберите ресторан">
              <Select.Option>Для всех ресторанов</Select.Option>
              {restaurants.map(({ id, title }) => (
                <Select.Option key={`option-restaurant-${id}`} value={id}>
                  {title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Пользователь" name="customer_phone">
            <InputMask mask={PHONE_MASK}>
              <Input
                type="phone"
                placeholder="Введите номер телефона"
                allowClear
              />
            </InputMask>
          </Form.Item>
          <Form.Item label="Время действия" name="fromTo">
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              format="DD.MM.YYYY"
            />
          </Form.Item>
          <Form.Item label="Комментарий" name="comment">
            <Input.TextArea placeholder="Ваш комментарий" />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space direction="vertical">
              <Form.Item name="active" valuePropName="checked" noStyle>
                <Checkbox disabled>Активен</Checkbox>
              </Form.Item>
              <Form.Item name="mobile" valuePropName="checked" noStyle>
                <Checkbox>Только для мобильных</Checkbox>
              </Form.Item>
              <Form.Item name="first" valuePropName="checked" noStyle>
                <Checkbox>Только для первого заказа</Checkbox>
              </Form.Item>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </ScreenLayout>
  )
}

export default RestaurantsScreen
