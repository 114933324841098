import React, { useState, useRef } from 'react'
import { useStore } from 'effector-react'
import {
  Table,
  Typography,
  Space,
  Tag,
  Button,
  Modal,
  Form,
  Upload,
  Select,
  Input,
  InputNumber,
  Switch,
  Row,
  Col,
  message,
  Tooltip,
  Alert,
  Divider,
} from 'antd'
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
  PlusOutlined,
  CarryOutOutlined,
  FileAddOutlined,
} from '@ant-design/icons'

import {
  $dishes,
  $dishActionPending,
  createDishFx,
  updateDishFx,
  deleteDishFx,
  setAllInStockFx,
  uploadCsvFx,
} from '../../../models/dishes'
import { $dishCategories } from '../../../models/dishCategories'
import { $optionGroups } from '../../../models/dishOptionGroups'
import { $selectedRestaurantId } from '../../../models/menus'
import { $token } from '../../../models/user'
import getColumnSearchProps from '../../../params/columnSearchProps'
import formLayout from '../../../params/formLayout'

const { Text } = Typography

function Dishes() {
  const csvInputRef = useRef()
  const [csvModal, setCsvModal] = useState(false)
  const [csvFile, setCsvFile] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [editingKey, setEditingKey] = useState(0)
  const [editItemAvailability, setEditItemAvailability] = useState(true)
  const [image, setImage] = useState([{ uid: '1', thumbUrl: '' }])
  const dishes = useStore($dishes)
  const dishCategories = useStore($dishCategories)
  const optionGroups = useStore($optionGroups)
  const dishActionPending = useStore($dishActionPending)
  const restaurantId = useStore($selectedRestaurantId)
  const token = useStore($token)
  const [form] = Form.useForm()
  const titleSearchRef = useRef()

  function onAddClick() {
    form.resetFields()
    setImage([{ uid: '1', thumbUrl: '' }])
    setEditingKey(0)
    setEditItemAvailability(true)
    setEditModal(true)
  }

  function onEditClick(record) {
    form.setFieldsValue(record)
    setImage([{ uid: '1', thumbUrl: record.image }])
    setEditingKey(record.id)
    setEditItemAvailability(record.available)
    setEditModal(true)
  }

  async function onSaveEditClick() {
    const formFields = await form.validateFields()
    const values = {
      ...formFields,
      qty_type: 'шт',
      maxCount: 10,
      available: editItemAvailability,
      image: image[0].thumbUrl,
    }
    try {
      if (editingKey > 0) {
        updateDishFx({
          shopId: restaurantId,
          itemId: editingKey,
          values,
        })
      } else {
        createDishFx({
          shopId: restaurantId,
          values,
        })
      }
      setEditModal(false)
    } catch (e) {
      console.log('Validate Failed:', e)
      return e
    }
  }

  function openDeletionModal(itemId) {
    Modal.confirm({
      title: 'Удаление блюда',
      icon: <ExclamationCircleOutlined />,
      content: 'Вы действительно хотите удалить это блюдо?',
      okButtonProps: {
        danger: true,
      },
      okText: 'Удалить',
      cancelText: 'Отмена',
      onOk: () => deleteDishFx({ shopId: restaurantId, itemId }),
    })
  }

  const columns = [
    {
      title: 'Категория',
      name: 'category',
      width: '15%',
      render: (_, { category_id }) => {
        const category = dishCategories.find(({ id }) => id === category_id)
        const title = category ? category.title : ''
        return <Text>{title}</Text>
      },
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        if (b.category && a.category) {
          if (b.category.position === 0) return false
          if (a.category.position === 0) return true

          return a.category.position - b.category.position
        }
        return false
      },
    },
    {
      title: 'Название',
      dataIndex: 'title',
      name: 'title',
      width: '30%',
      ...getColumnSearchProps('title', 'Поиск по названию', titleSearchRef),
    },
    {
      title: 'Стоимость',
      name: 'price',
      render: (_, { price }) => {
        return (
          <Text>
            {price}&nbsp;<Text type="secondary">&#8381;</Text>
          </Text>
        )
      },
      sorter: (a, b) => Number(a.price) - Number(b.price),
    },

    {
      title: 'Группы опций',
      name: 'option_groups',
      width: '30%',
      render: (_, { key: recordKey, groups }) => {
        if (groups) {
          return (
            <Space direction="vertical">
              {optionGroups
                .filter(({ id }) => groups.includes(id))
                .map(({ id, description }) => (
                  <Tag key={`${recordKey}-${id}`}>{description}</Tag>
                ))}
            </Space>
          )
        }
      },
    },
    {
      title: 'Скидка',
      name: 'discount',
      render: (_, { discount }) => {
        const type = discount > 0 ? '' : 'secondary'
        return (
          <Text type={type}>
            {discount}&nbsp;<Text type="secondary">%</Text>
          </Text>
        )
      },
      sorter: (a, b) => a.discount - b.discount,
    },
    {
      title: 'Наличие',
      name: 'out_of_stock',
      render: (_, record) => {
        const { id, out_of_stock } = record
        return (
          <Switch
            checked={!out_of_stock}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={() =>
              updateDishFx({
                shopId: restaurantId,
                itemId: id,
                values: { ...record, out_of_stock: !out_of_stock },
              })
            }
            loading={dishActionPending}
          />
        )
      },
      sorter: (a, b) => b.out_of_stock - a.out_of_stock,
    },
    {
      title: 'Действия',
      name: 'actions',
      width: 200,
      render: (_, record) => {
        const { id } = record
        return (
          <Space>
            <Button
              type="link"
              onClick={() => onEditClick(record)}
              disabled={dishActionPending}
            >
              Изменить
            </Button>
            <Button
              danger
              type="link"
              onClick={() => openDeletionModal(id)}
              disabled={dishActionPending}
            >
              Удалить
            </Button>
          </Space>
        )
      },
    },
  ]

  const imageUploaderProps = {
    action: '/api/owner/upload_image',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    listType: 'picture',
    showUploadList: { showRemoveIcon: false },
    beforeUpload: file => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error('Вы можете загрузить только JPG или PNG файл!')
      }
      const isLt100K = file.size <= 100000
      if (!isLt100K) {
        message.error('Размер файла не должен превышать 100KB!')
      }
      return isJpgOrPng && isLt100K
    },
    onChange(info) {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      fileList = fileList.map(file => {
        if (file.response) {
          file.thumbUrl = file.response.data.url
        }
        return file
      })
      if (info.file.size <= 100000) {
        setImage(fileList)
      }
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  return (
    <>
      <Space style={{ marginBottom: 16 }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={onAddClick}
          disabled={dishActionPending}
        >
          Добавить блюдо
        </Button>
        <Button
          type="primary"
          icon={<FileAddOutlined />}
          onClick={() => setCsvModal(true)}
        >
          Загрузить CSV
        </Button>
        <Divider type="vertical" />
        <Button
          icon={<CarryOutOutlined />}
          onClick={() => setAllInStockFx(restaurantId)}
          disabled={typeof restaurantId !== 'number' || restaurantId < 1}
        >
          Все в наличии
        </Button>
      </Space>
      <Table
        dataSource={dishes}
        columns={columns}
        scroll={{ x: true }}
        pagination={{ showSizeChanger: true }}
        bordered
      />
      <Modal
        title={editingKey > 0 ? 'Редактирование блюда' : 'Добавление товара'}
        width={640}
        visible={editModal}
        closable={false}
        centered
        okText="Сохранить"
        onOk={onSaveEditClick}
        onCancel={() => setEditModal(false)}
      >
        <Form {...formLayout} form={form} component={false}>
          <Form.Item label="Изображение" name="image">
            <Upload {...imageUploaderProps} fileList={image}>
              <Button>
                <UploadOutlined /> Загрузка изображения блюда
              </Button>
            </Upload>
            <Alert
              type="warning"
              message="Размер изображения не должен превышать 100 килобайт!"
              style={{ marginTop: 8 }}
            />
            <Button
              type="default"
              danger
              disabled={!image[0].thumbUrl}
              style={{ marginTop: 16 }}
              onClick={() => setImage([{ uid: '1', thumbUrl: '' }])}
            >
              Удалить изображение
            </Button>
          </Form.Item>
          <Form.Item
            label="Категория"
            name="category_id"
            rules={[
              {
                required: true,
                message: 'Обязательное поле!',
              },
            ]}
          >
            <Select placeholder="Выберите категорию блюд">
              {dishCategories.map(({ id, key, title }) => (
                <Select.Option value={id} key={key}>
                  {title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Название"
            name="title"
            rules={[
              {
                required: true,
                message: 'Обязательное поле!',
              },
            ]}
          >
            <Input placeholder="Введите название блюда" />
          </Form.Item>
          <Form.Item label="Описание" name="description">
            <Input.TextArea rows={4} placeholder="Введите описание блюда" />
          </Form.Item>
          <Form.Item label="Стоимость / Скидка">
            <Input.Group>
              <Row gutter={9}>
                <Col span={12}>
                  <Tooltip
                    title="Стоимость товара в ₽"
                    trigger={['focus']}
                    placement="topLeft"
                  >
                    <Form.Item
                      name="price"
                      rules={[
                        {
                          required: true,
                          message: 'Обязательное поле!',
                        },
                      ]}
                      style={{ margin: 0 }}
                    >
                      <InputNumber
                        min={0}
                        formatter={value => `${value}₽`}
                        parser={value => value.replace('₽', '')}
                        placeholder="Cтоимость товара в ₽"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Tooltip
                    title="Размер скидки на блюдо в %"
                    trigger={['focus']}
                    placement="topLeft"
                  >
                    <Form.Item name="discount" style={{ margin: 0 }}>
                      <InputNumber
                        min={0}
                        max={99}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        placeholder="Размер скидки в %"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
            </Input.Group>
          </Form.Item>
          <Form.Item name="maxCount" label="Макс. кол-во">
            <InputNumber
              min={0}
              defaultValue={10}
              disabled
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item label="Группы опций" name="groups">
            <Select mode="multiple" placeholder="Выберите группу опций">
              {optionGroups.map(group => (
                <Select.Option value={group.id} key={`opt-group-${group.id}`}>
                  {group.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Доступен">
            <Switch
              checked={editItemAvailability}
              onChange={() => setEditItemAvailability(!editItemAvailability)}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Загрузка CSV-файла"
        visible={csvModal}
        onCancel={() => setCsvModal(false)}
        cancelText="Отмена"
        okText="Загрузить"
        onOk={() => {
          if (restaurantId && csvFile) {
            const formData = new FormData()

            formData.append('shopId', restaurantId)
            formData.append('file', csvFile)

            uploadCsvFx(formData)
            setCsvModal(false)
          }
        }}
      >
        <input
          type="file"
          accept="text/csv"
          ref={csvInputRef}
          onChange={() => {
            const file = csvInputRef.current?.files[0]

            if (file) {
              setCsvFile(file)
            }
          }}
        />
      </Modal>
    </>
  )
}

export default Dishes
