import { useStore } from 'effector-react'
import React, { useEffect } from 'react'
import PageHeader from '../../components/PageHeader'
import ScreenLayout from '../../components/ScreenLayout'
import { $blacklist, loadBlackListFx } from '../../models/blacklist'
import BlackListForm from './BlackList/Form'
import BlackListTable from './BlackList/Table'

const BlackList = () => {
  const blacklist = useStore($blacklist)
  useEffect(() => {
    loadBlackListFx()
  }, [])

  return (
    <ScreenLayout>
      <PageHeader title="Чёрный список" />
      <BlackListForm />
      <BlackListTable data={blacklist} />
    </ScreenLayout>
  )
}

export default BlackList
