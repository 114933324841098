import { sample, combine } from 'effector'
import { notification } from 'antd'
import axios from 'axios'
import { $token, userLoggedOut } from '../user'
import { $restaurants } from '../restaurants'
import {
  loadPromocodesFx,
  createPromocodeFx,
  updatePromocodeFx,
  $rawPromocodesData,
  $promocodes,
} from './'

notification.config({
  duration: 2,
})

loadPromocodesFx.use(async () => {
  const res = await axios.get('/api/admin/promo', {
    headers: {
      Authorization: `Bearer ${$token.getState()}`,
    },
  })
  return res.data
})

loadPromocodesFx.fail.watch(({ error }) => {
  if (error.response.status) userLoggedOut()
})

createPromocodeFx.use(async promocode => {
  const res = await axios.post('/api/admin/promo', promocode, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${$token.getState()}`,
    },
  })
  return res.data
})

createPromocodeFx.done.watch(() => {
  notification['success']({
    message: 'Промокод успешно создан',
  })
})

createPromocodeFx.fail.watch(() => {
  notification['error']({
    message: 'Произошла ошибка',
  })
})

updatePromocodeFx.use(async promocode => {
  const res = await axios.put(`/api/admin/promo/${promocode.id}`, promocode, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${$token.getState()}`,
    },
  })
  return res.data
})

updatePromocodeFx.done.watch(() => {
  notification['success']({
    message: 'Промокод обновлён',
  })
})

updatePromocodeFx.fail.watch(() => {
  notification['error']({
    message: 'Произошла ошибка',
  })
})

$rawPromocodesData
  .on(loadPromocodesFx.done, (_, { result }) => result)
  .on(createPromocodeFx.done, (promocodes, { result }) => [
    ...promocodes,
    result,
  ])
  .on(updatePromocodeFx.done, (promocodes, { result }) =>
    promocodes.map(promocode =>
      promocode.id === result.id ? result : promocode,
    ),
  )

sample({
  source: combine({
    promocodes: $rawPromocodesData,
    restaurants: $restaurants,
  }),
  fn: ({ promocodes, restaurants }) =>
    promocodes.map(promocode => {
      const { id, shop_id } = promocode
      const key = `promocode-${id}`
      const restaurant =
        shop_id && restaurants.length > 0
          ? restaurants.find(({ id }) => id === shop_id)
          : null

      return {
        ...promocode,
        key,
        restaurant,
      }
    }),
  target: $promocodes,
})
