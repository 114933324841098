import React from 'react'
import { Redirect } from 'react-router-dom'
import { useStore } from 'effector-react'
import LoginScreen from './screens/LoginScreen'
import { $token } from '../models/user'

function Login() {
  const token = useStore($token)

  return !token ? <LoginScreen /> : <Redirect to="/" />
}

export default Login
