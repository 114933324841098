import { createEffect, createEvent, createStore } from 'effector'

export const loadOwnersFx = createEffect()
export const createOwnerFx = createEffect()
export const updateOwnerFx = createEffect()
export const updateOwnerDetailsFx = createEffect()
export const toggleOwnerStatusFx = createEffect()

export const $owners = createStore([])
export const $accountModal = createStore(false)
export const accountModalSet = createEvent('account modal was set')
export const $detailsModal = createStore(false)
export const detailsModalSet = createEvent('details modal was set')
