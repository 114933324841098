import axios from 'axios'
import moment from 'moment'
import {
  $allStats,
  $avrgCheckStats,
  $canceledStats,
  $earningStats,
  $orderStats,
  loadStatsFx,
} from '.'
import { $token } from '../user'

function formatPeriod(date, type) {
  const FORMAT = {
    day: 'DD.MM.YYYY',
    week: 'DD.MM.YYYY',
    month: 'MMM YYYY',
  }

  return moment(date).format(FORMAT[type])
}

loadStatsFx.use(async ({ restaurantId, period }) => {
  if (!restaurantId && !period) {
    throw new Error('Параметры запроса не указаны')
  }

  const {
    data: { code, data },
  } = await axios.get(`/api/admin/${restaurantId}/stats?period=${period}`, {
    headers: { Authorization: `Bearer ${$token.getState()}` },
  })

  if (code === 'ok') {
    return data
  } else {
    throw new Error('Не удалось запросить статистику')
  }
})

$allStats.on(loadStatsFx.done, (_, { result }) => {
  return result.reduce(
    (
      acc,
      {
        day,
        week,
        month,
        total,
        confirmed,
        canceled,
        earning,
        canceledEarning,
        confirmedEarning,
        serviceEarning,
        averageTotalSumIfConfirmed,
        percentOfCanceled,
        percentOfCanceledByCustomer,
        percentOfCanceledByRest,
      },
    ) => {
      const period = day
        ? formatPeriod(day, 'day')
        : week
        ? formatPeriod(week, 'week')
        : month
        ? formatPeriod(month, 'month')
        : undefined
      const result = [
        { period, value: total, category: 'Заказов всего', type: 'orders' },
        {
          period,
          value: confirmed,
          category: 'Заказов выполнено',
          type: 'orders',
        },
        {
          period,
          value: canceled,
          category: 'Заказов отменено',
          type: 'orders',
        },
        {
          period,
          value: earning,
          category: 'Выручка общая (руб.)',
          type: 'earning',
        },
        {
          period,
          value: confirmedEarning,
          category: 'Выручка с выполненных заказов (руб.)',
          type: 'earning',
        },
        {
          period,
          value: canceledEarning,
          category: 'Выручка с отменённых заказов (руб.)',
          type: 'earning',
        },
        {
          period,
          value: serviceEarning,
          category: 'Выручка сервиса (руб.)',
          type: 'earning',
        },
        {
          period,
          value: averageTotalSumIfConfirmed,
          category: 'Средний чек по выполненным заказам (руб.)',
          type: 'avrgCheck',
        },
        {
          period,
          value: percentOfCanceled,
          category: 'Заказов отменено (%)',
          type: 'canceled',
        },
        {
          period,
          value: percentOfCanceledByCustomer,
          category: 'Заказов отменено клиентом (%)',
          type: 'canceled',
        },
        {
          period,
          value: percentOfCanceledByRest,
          category: 'Заказов отменено рестораном (%)',
          type: 'canceled',
        },
      ]

      acc = [
        ...acc,
        ...result.map(item => ({ ...item, value: Number(item.value) })),
      ]

      return acc
    },
    [],
  )
})

$orderStats.on($allStats, (_, stats) =>
  stats.filter(({ type }) => type === 'orders'),
)
$earningStats.on($allStats, (_, stats) =>
  stats.filter(({ type }) => type === 'earning'),
)
$avrgCheckStats.on($allStats, (_, stats) =>
  stats.filter(({ type }) => type === 'avrgCheck'),
)
$canceledStats.on($allStats, (_, stats) =>
  stats.filter(({ type }) => type === 'canceled'),
)
