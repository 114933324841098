import React, { useEffect, useState } from 'react'
import { useStore } from 'effector-react'
import Select from 'antd/lib/select'
import { $restaurants } from '../../../models/restaurants'

const RSTRNT_LSKEY = 'stats_rstrnt'
const DEFAULT_VALUE = { value: 'all', label: 'Все рестораны' }

const RestaurantSelect = ({ onSelect = () => null }) => {
  const restaurants = useStore($restaurants)
  const [filter, setFilter] = useState('')
  const [filteredOptions, setFilteredOptions] = useState([])
  const [value, setValue] = useState(DEFAULT_VALUE)

  useEffect(() => {
    const ls = localStorage.getItem(RSTRNT_LSKEY)

    if (ls) {
      const parsedValue = JSON.parse(ls)
      setValue(parsedValue)
      onSelect(parsedValue.value)
    }
  }, [onSelect])

  useEffect(() => {
    const _options = restaurants.map(({ id, title }) => ({
      value: id,
      label: title,
    }))

    const _filteredOptions = _options.filter(({ label }) =>
      label.toLowerCase().includes(filter.toLowerCase()),
    )

    setFilteredOptions([DEFAULT_VALUE, ..._filteredOptions])
  }, [filter, restaurants])

  function handleSelect(_, value) {
    const _value = value || DEFAULT_VALUE
    localStorage.setItem(RSTRNT_LSKEY, JSON.stringify(_value))
    setValue(_value)
    onSelect(_value.value)
  }

  return (
    <Select
      showSearch
      allowClear
      value={value}
      defaultValue={DEFAULT_VALUE}
      onSearch={setFilter}
      options={filteredOptions}
      onSelect={handleSelect}
      onClear={handleSelect}
      labelInValue={true}
      filterOption={false}
      notFoundContent={null}
      style={{ width: 320 }}
    />
  )
}

export default RestaurantSelect
