import { sample } from 'effector'
import axios from 'axios'
import { $token } from '../user'
import {
  loadOptionGroupsFx,
  createOptionGroupFx,
  updateOptionGroupFx,
  $rawOptionGroups,
  $optionGroups,
} from './'

loadOptionGroupsFx.use(async shopId => {
  const res = await axios.get(`/api/owner/shops/${shopId}/groups`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${$token.getState()}`,
    },
  })
  return res.data.data
})

createOptionGroupFx.use(async ({ shopId, group }) => {
  const res = await axios.post(
    `/api/owner/shops/${shopId}/groups`,
    { ...group },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${$token.getState()}`,
      },
    },
  )
  return res.data.data
})

updateOptionGroupFx.use(async ({ shopId, groupId, group }) => {
  const res = await axios.put(
    `/api/owner/shops/${shopId}/groups/${groupId}`,
    { ...group },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${$token.getState()}`,
      },
    },
  )
  return res.data.data
})

$rawOptionGroups
  .on(loadOptionGroupsFx.done, (_, { result }) => result)
  .on(createOptionGroupFx.done, (groups, { result }) => [...groups, result])
  .on(updateOptionGroupFx.done, (groups, { result }) =>
    groups.map(group => (group.id === result.id ? result : group)),
  )

sample({
  source: $rawOptionGroups,
  fn: rawOptionGroups =>
    rawOptionGroups.map(group => ({ ...group, key: `opt-group-${group.id}` })),
  target: $optionGroups,
})
