import React from 'react'
import styled from 'styled-components'
import { Layout } from 'antd'

const Content = styled(Layout.Content)`
  min-height: 100vh;
  padding: 32px 24px 96px;
`

function ScreenLayout({ children }) {
  return (
    <Layout>
      <Content>{children}</Content>
    </Layout>
  )
}

export default ScreenLayout
