import message from 'antd/lib/message'
import moment from 'moment'
import Axios from 'axios'
import {
  loadTransactionsFx,
  $transactions,
  makeTransactionFx,
  $balance,
  loadAllTransactionsFx,
  $allTransactions,
  $allTransactionsMeta,
} from '.'
import { $token } from '../user'
import axios from 'axios'

loadTransactionsFx.use(async accountId => {
  const token = $token.getState()

  const { data } = await Axios.get(`/api/admin/transactions/${accountId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })

  if (data.code === 'error') {
    message.error('Не удалось загрузить список транзакций')
    throw new Error(data.message)
  } else {
    return data.data
  }
})

loadAllTransactionsFx.use(async ({ page = 1, perPage = 10 }) => {
  const { data } = await axios.get(
    `/api/admin/transactions/?page=${page}&perPage=${perPage}`,
    {
      headers: { Authorization: `Bearer ${$token.getState()}` },
    },
  )

  if (data.code === 'ok') {
    return { data: data.data, meta: data.meta }
  } else {
    throw new Error(data.message)
  }
})

makeTransactionFx.use(async ({ amount, description, accountId, onSuccess }) => {
  const token = $token.getState()

  const { data } = await Axios.post(
    '/api/admin/transactions',
    { amount: `${amount}`, description, account_id: accountId },
    { headers: { Authorization: `Bearer ${token}` } },
  )

  if (data.code === 'error') {
    message.error('Не удалось создать транзакцию')
    throw new Error(data.message)
  } else {
    onSuccess()
    return data.data
  }
})

/**
 * @returns String
 * @param {String} amount
 */
function addHundredth(amount) {
  const cents = amount.split('.')[1]
  return cents?.length < 2 ? amount + '0' : amount
}

$balance
  .on(loadTransactionsFx.done, (_, { result: { balance } }) => {
    return addHundredth(balance)
  })
  .on(makeTransactionFx.done, (balance, { result }) => {
    const newBalance = Number(balance) + Number(result.amount)
    return addHundredth(newBalance.toFixed(2).toString())
  })

$transactions
  .on(loadTransactionsFx.done, (_, { result: { transactions } }) => {
    return transactions.sort(
      (a, b) => moment(b.createdAt) - moment(a.createdAt),
    )
  })
  .on(makeTransactionFx.done, (transactions, { result }) => {
    const { admin_id, report_id, billing_id } = result

    return [
      {
        ...result,
        admin: admin_id,
        report: report_id,
        billing: billing_id,
      },
      ...transactions,
    ]
  })

$allTransactions.on(
  loadAllTransactionsFx.done,
  (_, { result: { data } }) => data,
)

$allTransactionsMeta.on(
  loadAllTransactionsFx.done,
  (_, { result: { meta } }) => meta,
)
