import Axios from 'axios'
import { sample } from 'effector'
import {
  $enreachedStories,
  $stories,
  createStoryFx,
  loadStoriesFx,
  updateStoryFx,
} from '.'
import { $token } from '../user'

loadStoriesFx.use(async () => {
  const { data } = await Axios.get('/api/stories')
  return data.code !== 'ok' ? [] : data.data
})

createStoryFx.use(async params => {
  const token = $token.getState()
  const { data } = await Axios.post('/api/admin/stories', params, {
    headers: { Authorization: `Bearer ${token}` },
  })

  return data
})

updateStoryFx.use(async ({ id, params }) => {
  const token = $token.getState()
  const { data } = await Axios.put(`/api/admin/stories/${id}`, params, {
    headers: { Authorization: `Bearer ${token}` },
  })

  return data
})

$stories
  .on(loadStoriesFx.done, (_, { result }) => result)
  .on(createStoryFx.done, (stories, { result }) =>
    result.code === 'ok' ? [...stories, result.data] : stories,
  )
  .on(updateStoryFx.done, (stories, { result }) =>
    result.code === 'ok'
      ? stories.map(story =>
          story.id === result.data.id ? result.data : story,
        )
      : stories,
  )

sample({
  source: $stories,
  fn: stories =>
    stories
      .map(story => ({ ...story, key: `story-${story.id}` }))
      .sort((a, b) => a.order - b.order),
  target: $enreachedStories,
})
