import { sample } from 'effector'
import axios from 'axios'
import { $token, userLoggedOut } from '../user'
import {
  loadRestaurantCategoriesFx,
  updateRestaurantCategoryFx,
  createRestaurantCategoryFx,
  $rawRestaurantCategoriesData,
  $restaurantCategories,
} from './'

loadRestaurantCategoriesFx.use(async () => {
  const res = await axios.get('/api/admin/dishcategories', {
    headers: {
      Authorization: `Bearer ${$token.getState()}`,
    },
  })
  return res.data.data
})

loadRestaurantCategoriesFx.fail.watch(({ error }) => {
  if (error.response.status) userLoggedOut()
})

updateRestaurantCategoryFx.use(async ({ dishcategory_id, values }) => {
  const res = await axios.put(
    `/api/admin/dishcategories/${dishcategory_id}`,
    values,
    {
      headers: {
        Authorization: `Bearer ${$token.getState()}`,
        'Content-Type': 'application/json',
      },
    },
  )
  return res.data.data
})

createRestaurantCategoryFx.use(async values => {
  const res = await axios.post(`/api/admin/dishcategories`, values, {
    headers: {
      Authorization: `Bearer ${$token.getState()}`,
      'Content-Type': 'application/json',
    },
  })
  return res.data.newRestaurantCategory
})

$rawRestaurantCategoriesData
  .on(loadRestaurantCategoriesFx.done, (_, { result }) => result)
  .on(updateRestaurantCategoryFx.done, (categories, { result }) =>
    categories.map(category => (category.id === result.id ? result : category)),
  )
  .on(createRestaurantCategoryFx.done, (categories, { result }) => [
    ...categories,
    result,
  ])

sample({
  source: $rawRestaurantCategoriesData,
  fn: rawRestaurantCategories =>
    rawRestaurantCategories.map(category => {
      const { id } = category
      const key = `dish-category-${id}`
      return { ...category, key }
    }),
  target: $restaurantCategories,
})
