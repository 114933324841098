import React, { useEffect, useState } from 'react'
import Space from 'antd/lib/space'
import Input from 'antd/lib/input'
import { SearchOutlined } from '@ant-design/icons'
import { Button } from 'antd'

const Search = ({ onSearch = value => value }) => {
  const [value, setValue] = useState('')
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    onSearch(searchValue.toLowerCase())
  }, [searchValue, onSearch])

  return (
    <Space>
      <Input
        placeholder="Введите запрос"
        value={value}
        onChange={({ target: { value } }) => setValue(value)}
        onPressEnter={() => setSearchValue(value)}
        style={{ width: 240 }}
      />
      <Button
        type="primary"
        icon={<SearchOutlined />}
        onClick={() => setSearchValue(value)}
      >
        Поиск
      </Button>
      <Button
        disabled={!value}
        onClick={() => {
          setValue('')
          setSearchValue('')
        }}
      >
        Сброс
      </Button>
    </Space>
  )
}

export default Search
