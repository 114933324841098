import React, { useEffect } from 'react'
import Form from 'antd/lib/form'
import Space from 'antd/lib/space'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input'

import formLayout, { tailLayout } from '../../../params/formLayout'
import { createOwnerFx, updateOwnerFx } from '../../../models/owners'

const AccountForm = ({ account }) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
  }, [account, form])

  function handleSubmit(values) {
    if (account) {
      updateOwnerFx({ owner_id: account.id, values })
    } else {
      createOwnerFx(values)
    }
  }

  return (
    <Form
      form={form}
      component={false}
      initialValues={account}
      onFinish={handleSubmit}
      {...formLayout}
    >
      <Form.Item
        label="Эл. почта"
        name="email"
        rules={[{ required: true, message: 'Это обязательное поле!' }]}
      >
        <Input placeholder="Введите адрес электронной почты" />
      </Form.Item>
      <Form.Item
        label="Пароль"
        name="password"
        rules={[{ required: true, message: 'Это обязательное поле!' }]}
      >
        <Input
          type="password"
          placeholder={`${!account ? 'Новый' : 'Придумайте'} пароль`}
        />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Space>
          <Button onClick={() => form.resetFields()}>Сброс</Button>
          <Button type="primary" onClick={() => form.submit()}>
            Сохранить
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default AccountForm
