import { createStore, createEvent } from 'effector'

export const restaurantSelected = createEvent('a restaurant was selected')
export const restaurantsSearched = createEvent(
  'the list of the restaurants was searched',
)
export const restaurantSearchResetted = createEvent(
  'restaurants search was resetted',
)

export const $selectedRestaurantId = createStore(null)
export const $selectedRestaurant = createStore({})
export const $restaurantsQueryString = createStore('')
export const $filteredRestaurants = createStore([])
