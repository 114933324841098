import React, { Fragment, useEffect, useState } from 'react'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Divider from 'antd/lib/divider'
import Modal from 'antd/lib/modal'
import Text from 'antd/lib/typography/Text'
import Select from 'antd/lib/select'
import {
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { addAddressesFx, deleteAddressFx } from '../models/restAddresses'
import { useStore } from 'effector-react'
import { $restaurants } from '../models/restaurants'

const AddressForm = ({ shopId = 0 }) => {
  const [initials, setInitials] = useState([])
  const restaurants = useStore($restaurants)
  const [suggestions, setSuggestions] = useState([])
  const [selectedValue, setSelectedValue] = useState('')

  useEffect(() => {
    setSelectedValue('')
    setSuggestions([])
  }, [shopId])

  useEffect(() => {
    if (shopId > 0) {
      const restaurant = restaurants.find(({ id }) => id === shopId)

      if (restaurant && restaurant.shopAddresses) {
        setInitials(restaurant.shopAddresses)
      }
    }
  }, [shopId, restaurants])

  const getSuggestions = value => {
    if (window?.ymaps) {
      window.ymaps
        .suggest(value, {
          boundedBy: [
            [46.23, 44.13],
            [46.36, 44.43],
          ],
        })
        .then(res => {
          setSuggestions(
            res
              .map(({ value }) => {
                const reducedValue = value.split('Калмыкия, ')[1]
                return { value: reducedValue, label: reducedValue }
              })
              .filter(
                ({ value }) =>
                  value.length > 0 &&
                  ['Элиста', 'Троицкое'].some(place => value.includes(place)),
              ),
          )
        })
    } else {
      setSuggestions([])
    }
  }

  return (
    <Fragment>
      <Row gutter={16}>
        <Col span={21}>
          <Select
            showSearch
            placeholder="Начните вводить адрес"
            notFoundContent="Ничего не найдено"
            style={{ width: '100%' }}
            suffixIcon={<SearchOutlined />}
            onSearch={getSuggestions}
            autoClearSearchValue={false}
            options={suggestions}
            filterOption={false}
            value={selectedValue}
            onChange={setSelectedValue}
          />
        </Col>
        <Col span={3}>
          <Button
            type="primary"
            block
            icon={<PlusOutlined />}
            disabled={!selectedValue}
            onClick={() => {
              addAddressesFx({ id: shopId, addressLine: selectedValue })
              setSelectedValue('')
              setSuggestions([])
            }}
          />
        </Col>
      </Row>
      {initials.length > 0 && <Divider />}
      {initials.map(({ id, address }) => (
        <Row key={address} style={{ marginBottom: 16 }} gutter={16}>
          <Col span={21}>
            <Input value={address} readOnly />
          </Col>
          <Col span={3}>
            <Button
              danger
              block
              icon={<DeleteOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: `Удаление адреса`,
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <Text>
                      Вы действительно хотите удалить адрес{' '}
                      <Text strong style={{ whiteSpace: 'nowrap' }}>
                        {address}
                      </Text>
                      ?
                    </Text>
                  ),
                  cancelText: 'Отмена',
                  okText: 'Удалить',
                  okButtonProps: { danger: true },
                  onOk() {
                    deleteAddressFx({ shopId, addressId: id })
                  },
                })
              }}
            />
          </Col>
        </Row>
      ))}
    </Fragment>
  )
}

export default AddressForm
