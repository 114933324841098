import { sample, combine } from 'effector'
import { $restaurants } from '../restaurants'
import {
  $selectedRestaurantId,
  $selectedRestaurant,
  $restaurantsQueryString,
  $filteredRestaurants,
  restaurantSelected,
  restaurantsSearched,
  restaurantSearchResetted,
} from './'

const selectedRestaurantKey = 'admin_selected_restaurant'

$selectedRestaurantId.on(restaurantSelected, (_, id) => id && Number(id))
$restaurantsQueryString
  .on(restaurantsSearched, (_, query) => query)
  .reset(restaurantSearchResetted)

sample({
  source: combine({
    restaurants: $restaurants,
    query: $restaurantsQueryString,
  }),
  fn: ({ restaurants, query }) => {
    return restaurants.filter(({ title }) =>
      title.toLowerCase().includes(query.toLowerCase()),
    )
  },
  target: $filteredRestaurants,
})

sample({
  source: combine({
    restaurants: $restaurants,
    selectedId: $selectedRestaurantId,
  }),
  fn: ({ restaurants, selectedId }) => {
    return restaurants.filter(({ id }) => id === selectedId) || {}
  },
  target: $selectedRestaurant,
})

$selectedRestaurantId.updates.watch(id => {
  localStorage.setItem(selectedRestaurantKey, id)
})

try {
  const id = localStorage.getItem(selectedRestaurantKey)
  if (!id) {
    throw new Error('Нет сохранённого ресторана')
  }
  restaurantSelected(id)
} catch (e) {
  console.log(e)
}
