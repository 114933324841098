import React, { useState } from 'react'
import { useStore } from 'effector-react'
import { Table, Modal, Form, Button, Input, InputNumber } from 'antd'

import PageHeader from '../../components/PageHeader'
import ScreenLayout from '../../components/ScreenLayout'
import {
  $restaurantCategories,
  loadRestaurantCategoriesFx,
  createRestaurantCategoryFx,
  updateRestaurantCategoryFx,
} from '../../models/restaurantCategories'
import formLayout from '../../params/formLayout'

function RestaurantsScreen() {
  const [editModal, setEditModal] = useState(false)
  const [editingKey, setEditingKey] = useState(0)
  const restaurantCategories = useStore($restaurantCategories)
  const loading = useStore(loadRestaurantCategoriesFx.pending)
  const [form] = Form.useForm()

  function onAddClick() {
    setEditModal(true)
    setEditingKey(0)
    form.resetFields()
  }

  function onEditClick(record) {
    setEditModal(true)
    setEditingKey(record.id)
    form.setFieldsValue(record)
  }

  async function onSaveEditClick() {
    const values = await form.validateFields()
    values.translit = values.translit.toLowerCase()

    try {
      if (editingKey > 0) {
        updateRestaurantCategoryFx({ dishcategory_id: editingKey, values })
      } else {
        createRestaurantCategoryFx(values)
      }
      setEditModal(false)
    } catch (e) {
      console.log(e)
    }
  }

  const columns = [
    {
      title: 'Название',
      dataIndex: 'title',
      name: 'title',
    },
    {
      title: 'Позиция',
      dataIndex: 'position',
      name: 'position',
    },
    {
      title: 'Транслит',
      dataIndex: 'translit',
      name: 'translit',
    },
    {
      title: 'Действия',
      name: 'actions',
      render: (_, record) => {
        return (
          <Button type="link" onClick={() => onEditClick(record)}>
            Изменить
          </Button>
        )
      },
    },
  ]

  return (
    <ScreenLayout>
      <PageHeader title="Категории ресторанов" />
      <Button type="primary" style={{ marginBottom: 16 }} onClick={onAddClick}>
        Добавить категорию
      </Button>
      <Table
        columns={columns}
        dataSource={restaurantCategories}
        loading={loading}
        scroll={{ x: true }}
        pagination={{ showSizeChanger: true }}
        bordered
      />
      <Modal
        title={
          editingKey > 0 ? 'Редактирование категории' : 'Добавление категории'
        }
        visible={editModal}
        onCancel={() => setEditModal(false)}
        closable={false}
        centered
        okText="Сохранить"
        width={640}
        onOk={onSaveEditClick}
      >
        <Form {...formLayout} form={form} component={false}>
          <Form.Item
            label="Название"
            name="title"
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <Input placeholder="Введите название категории" />
          </Form.Item>
          <Form.Item label="Позиция" name="position">
            <InputNumber
              min={1}
              placeholder="Укажите позицию категории"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            label="Транслит"
            name="translit"
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <Input placeholder="Задайте транлитерацию названия" />
          </Form.Item>
        </Form>
      </Modal>
    </ScreenLayout>
  )
}

export default RestaurantsScreen
